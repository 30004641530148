import React from 'react';

const SiteIntro = ({ doubleColumn }) => {
  return (
    <React.Fragment>
      <div className={doubleColumn ? "intro double-col" : "intro"}>
        <span className="mobile">
          <span className="highlight-yellow gray-drop-shadow">Sean writes genre fiction</span>.
          Whatever that means. He grew up on Transformers (the cartoon, not Michael Bay),
          Thundercats (Ho!), Voltron, Robotech, Starblazers, Kung Fu Theater,
          boomboxes, and mixtapes. He loves Tolkien, Guy Ritchie, Guillermo del Toro, and Star Wars. </span>
        <span className="mobile">
          When he's not making stuff up and writing it down, he's slinging pixels
          in Silicon Valley, hanging out with his wife and two pups, and riding his
          motorcycle between cars faster than he probably should. You can see what
          Sean's up to by following him on twitter where he posts as <a className="gray-drop-shadow" href="http://twitter.com/AuthorSeanAllen" target="_blank" rel="noopener noreferrer">@AuthorSeanAllen</a>.
        </span>
      </div>
    </React.Fragment>
  )
}

export default SiteIntro;