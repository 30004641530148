export const booksMeta = {
  Deceit: {
    title: 'Deceit',
    releaseDate: 'July 30, 2021 00:00:00',
    series: 'The D-Evolution',
    numberInSeries: 'I',
    nextInSeries: true,
    nextBookImage: 'Decline.png',
    nextBookName: 'Decline',
    available: true,
    amazonUSLink: 'https://www.amazon.com/dp/B09BDCDS62',
    amazonUKLink: 'https://www.amazon.co.uk/dp/B09BDCDS62',
    appleLink: 'https://books.apple.com/us/book/id1578565937',
    koboLink: 'https://www.kobo.com/us/en/ebook/deceit-35',
    bnLink: 'https://www.barnesandnoble.com/w/deceit-sean-allen/1139914075?ean=2940162550471',
    bnPBLink: 'https://www.barnesandnoble.com/w/deceit-sean-allen/1139914075?ean=9781953949011',
    bnHCLink: 'https://www.barnesandnoble.com/w/deceit-sean-allen/1139914075?ean=9781953949028',
    image: 'DeceitWebCover_LARGE.jpeg',
    thumbImg: 'DeceitThumb.jpg',
    imgPosition: 'left',
    textPosition: 'right',
    blurb: {
      __html: `<p><span class="highlight-yellow">A galaxy on the edge of crumbling. 
      A human who shouldn’t exist. One last chance to save the stars.</span></p>

      <p>Colonel Jerrel Abalias is furious with himself. After <span class="highlight-yellow">
      his best soldier dies at the hands of an assassin,</span> he fears his failure
      may have cost the Dissension its last hope of winning the ages-long war. 
      <span class="highlight-yellow">But one of his troops recognizes the killer’s ship, 
      and the hard-bitten warrior is determined to hunt it to the ends of the galaxy.</span></p>

      <p>Dezmara Strykar is too cunning for her own good. <span class="highlight-yellow">After waking from cryosleep
      to find her race all but extinct and her memories completely wiped, the expert pilot
      is desperate for a sign she's not the only human left alive.</span> But to fund her near-hopeless
      search, she turns to smuggling and gains the kind of notoriety that draws dangerous attention.</p>

      <p>Setting a trap to bring the murderer to justice, Abalias races against time to salvage his
      people's crumbling hope. And accused of a crime she didn't commit, Dezmara finds herself pursued 
      by an entire army bent on her destruction.</p>

      <p><span class="highlight-yellow">Can the gutsy survivor escape an ever-tightening noose, or will the
      Dissension hang her for a betrayal she knows nothing about?</p></span>

      <p><i>Deceit</i> the pulse-pounding first book in The D-Evolution space opera fantasy series. 
      <span class="highlight-yellow">If you like electrifying characters, sprawling universes, and gritty thrill rides,</span> 
      then you'll love Sean Allen's chase through the cosmos.</p>
      
      <p>Buy <i>Deceit</i> to navigate the treacherous void today!</p>`
    },

    extract: {
      __html: `
        <h3 class="extract-header">Dark Stranger</h3>

        <p><span class="smallcaps"><strong>“W</strong>aiting like death</span>,” Malo whispered.

        <p>Talfus glanced at his partner.</p>

        <p>He wasn’t much for talk. None of his kind were. 
        But Malo never wasted a word, and Talfus preferred it that way. 
        There were too many stories these days. Too much lying and 
        bragging—too much bullshit. <i>But I guess that’s what 
        happens when the truth is more terrible than anything else.</i> 
        Still, Talfus appreciated Malo’s straightforward way.</p>

        <p>Most folks thought Moxen were simple in the head. But the miners were stronger than most, with resolve like 
        forged metal and a fierce loyalty to friends and kin alike. He stood alongside Talfus, some yards from the 
        cliff’s edge, scanning the horizon, dark eyes flitting back and forth, peering through dark clouds overhead. 
        His battle hammer sat on the ground beside him, tipped on its bludgeon, and the Moxen gripped the handle, 
        thick fist clenching the hide-bound loop at its end. Talfus stared at Malo from horn tips to hooves, 
        then smiled and shook his head. Calling the Moxen big didn’t do him justice. <i>Giant</i> was much closer to the mark.</p>

        <p>The crown of his head was eight feet high and some inches, the horns curling from either side adding 
        two more feet at least. Tan fur covered his muscled frame, all of it short, save for the dark whiskers 
        tracing his jaw. His beard danced, fluttering on the wind as the gathering storm raised its voice, 
        and Talfus smirked. More often than not, that kind of thing would’ve made the Moxen chuckle. 
        But not today. Today he stood like a stone—eyes, beard, and clenching fist the only signs Malo was a living thing.</p>

        <p>Talfus never liked to see his friend worry. But he understood. The curve on his mouth faded, 
        and he turned his gaze back to the sky. The two of them had scouted point for more rendezvous 
        than Talfus could count. But this time they weren’t looking for the usual delivery. 
        <i>This isn’t a transport hauling food, weapons, or med-kits. 
        This might be the only thing that can save us all from ruin.</i></p>

        <p>“Waiting like death,” Malo whispered again.</p>

        <p>“Patience, my friend,” said Talfus. “He’ll be here.”</p>

        <p>“How you know?” Malo said, glancing down. “Waadi wisdom?”</p>

        <p>Talfus shook his head, the smirk playing at his lips again. “No,” he said. 
        “The Waadi aren’t any wiser than the Moxen. But I do know a bit about the Mewlatai.”</p>

        <p>“What about them?” said Malo.</p>

        <p>“The Serum maker swore he’d be here. And the Mewlatai would die on their 
        own swords before they betrayed their code.” <i>As long as he’s an honorable mew. 
        Gods of the Brine, please let him be honorable.</i></p>

        <p>Malo wrinkled his brow and looked skyward again.</p>

        <p>Lightning crackled through the clouds and Talfus grimaced, the electric heat stinging on his face. 
        The storms on Sitiri-9 were the worst kind for a creature born of Mother Sea. All wind and lightning, 
        and hardly a lick of wet. He touched his arm, and his scowl deepened. It’d been too 
        long since he’d had a good soaking, and it showed, a film of gray dulling the once-blue shine of his scales. 
        “I wish it would rain just once on this forsaken rock!” he said, lifting his hand.</p>

        <p>Malo chuckled, a deep rumble from his gut.</p>

        <p>“What’s so funny?” said Talfus.</p>

        <p>“Fish man out of water. Very funny!”</p>

        <p>Talfus smiled, and what started as a chuckle between friends burst into laughter. </p>
        It was a dangerous risk to take. Talfus knew it, and Malo did too, he was sure. 
        A Durax scout looking for Dissenters could’ve heard them from the valley below. 
        Hell, a half-dead goat could’ve heard them from the next gorge over. But their mission was dire, 
        and the light air welcome. They tried to hold back, hands clamping their mouths, spluttering and 
        snorting. But it was no use. Talfus and Malo laughed, bellies and shoulders bouncing, both of them 
        pawing tears from their eyes.</p>

        <p>They made an odd pair, to say the least. Under any other circumstances, 
        Talfus wouldn’t have known the Moxen existed. And, truth be told, he wouldn’t 
        have cared much if he’d found out they had—just another race of people in a galaxy 
        full of countless others. But that was one good thing about the Duraxian War. 
        Perhaps the <i>only</i> good thing. Talfus had found a brother he’d never known he had.</p>
        
        <p>“Think about family today?” Malo said.</p>

        <p>It was one of his favorite questions. But no matter how many times he asked, 
        it never failed to hitch the breath in Talfus’s chest. When they’d first met, 
        Talfus was hell-set on forgetting his blood and the terrible things that had 
        happened to them. But Malo started each day remembering his kin, drawing strength 
        from their time together. And soon Talfus was doing the same. “Of course,” he said, 
        tears stinging his eyes. “No more thoughts about forgetting. I know now that would 
        be torment worse than facing all the Durax alone. Thanks to you.” 
        He smiled up at Malo, and the Moxen smiled back.</p>
        
        <p>A red glow shone in the corner of his sight, and Talfus eyed the transponder 
        strapped to his rifle. The light pulsed quicker, and he took the gun in both hands 
        as Malo hefted up his hammer. A faint growl cut the wind, its rumble swelling as 
        the beacon beat faster. The lens flared solid crimson, and the underbelly of a Zebulon 
        star freighter slipped through the clouds up ahead.</p>
        
        <p>It was a beautiful machine, dark and smooth, its rounded nose flowing to its 
        swept-back wings, its wings curving softly back to its fuselage. A low cockpit 
        sat near the front, its small windows almost invisible. If the ship had landing 
        lights, Talfus couldn’t tell where they should be on the seamless body, and its 
        black finish failed to catch even a glint of lightning arcing the sky. It was a 
        ghost ship, built to disappear among the stars and outrun anyone who might stumble 
        across its path. A ship, Talfus thought, quite befitting the legend of the Mewlatai.</p>
        
        <p>Four doors snapped wide on the underside of the freighter, and Talfus leveled his rifle. 
        The craft hung there for a moment, engines churning against the storm, then landing skids 
        drifted through the breaches. They weren’t automatics or cannons, but Talfus kept his gun 
        pressed tight to his shoulder, trigger finger at the ready.</p>
        
        <p>“Why not land?” grunted Malo. Talfus couldn’t see him, but he knew Malo was in position. 
        He’d be standing a stride left and back from Talfus, clear of the Waadi’s line of fire, 
        ready to set loose his hammer should any foot soldiers arrive.</p>
        
        <p>“I’d guess he’s scanning the area for signs of an ambush,” said Talfus.</p>
        
        <p>“Good idea. Why not us do?”</p>
        
        <p>“A scan could be traced from orbit without a signal shield,” Talfus said. 
        “The ship’s hull protects its transmissions. But we’ve got the next best thing. 
        I posted lookouts on the north and south ridges of the plateau. If they see anything 
        that looks like a Durax raid, they’ll signal us to abort.”</p>
        
        <p>“What sign Malo look for?”</p>
        
        <p>Talfus eased his gun barrel down and turned. “If this light on my gun flashes green, 
        I want you to stop what you’re doing and run. I want you to run back into the mine, 
        and use the tunnels to lose anyone chasing you.” He glanced at the crooked cavern 
        yawning behind them. “Do you understand?”</p>
        
        <p>The Moxen shook his head. “Malo not leave without Talfus.”</p>
        
        <p>“Malo, I’m giving you an order,” Talfus said. “I’m a captain and <i>you</i> are a lieutenant. 
        You’re supposed to follow my instructions.”</p>
        
        <p>“Malo die by fish man’s side. Won’t leave friend.”</p>
        
        <p>Talfus knew what was coming. They’d been through this before. 
        But he’d never once stopped Malo from singing. And, if he was honest, he never would. 
        Song, as the Moxen called it, was how they talked with one another. They made notes and 
        harmonies to shame even the grandest singers in the days before the Durax. That alone 
        would’ve been enough to let Malo go on. But Moxen song also affected the heart. 
        Talfus had lost count of how many times Malo had sung him courage, stoking his fight as 
        Durax and Berzerkers bore down on them. The Moxen had sung him peace too, 
        when visions of his family torn to bits had haunted his dreams.</p>
        
        <p>The first note drifted from Malo’s lips, and Talfus smiled. 
        He knew it was coming, but the Waadi never tired of this song. 
        Malo called it “The Song of Brothers,” and that was what Talfus 
        felt in his heart—brotherhood. His bond to the Moxen burned 
        brightly in his chest, then drifted farther, across the heavens, 
        its warmth shining on the waters of Mother Sea, where his own 
        brothers swam in her eternal arms. It was only a handful of notes. 
        But Talfus always felt like time stood still when the Moxen sang.</p>
        
        <p>Malo ended his ballad and smiled. “Malo have heart and strength to carry Talfus,” he said. 
        “Won’t leave fish man behind.”</p>
        
        <p>Talfus couldn’t argue with the Moxen. At least, not in earnest. 
        He felt the same way. The moment the Dissension had paired them up, 
        he knew the strongest Waadi couldn’t pull a Moxen out of harm’s way. 
        But not long after their first mission together, Talfus had made a decision. 
        If Malo were ever injured in battle and couldn’t escape, he would die fighting by the Moxen’s side.</p>
        
        <p>“Malo, my friend,” Talfus said, smiling, “you have enough heart to move mountains.”</p>
        
        <p>The Zebulon’s engines revved higher, and Talfus turned, 
        raising his gun as the ship hovered to the ground. It jostled to a stop, 
        the growl of its motors fading beneath the wind. A door alongside the cockpit swung open, 
        and Talfus snugged the rifle to his shoulder again, peering down the sights at the dark portal.</p>
        
        <p>“How we know if Mewlatai?” said Malo.</p>
        
        <p>Talfus kept his eye trained on the doorway. “I think we’ll know him by sight,” he said. 
        “I’ve heard you can’t mistake them for any other race. 
        That they have a certain . . .” Talfus paused. “. . . <i>way</i> about them.” 
        Talfus braced against the storm, clicking off the seconds in his head as 
        the wind stirred and the portal stayed dark. He stood firm, drawing air slowly 
        into his belly then out again, praying the breath would starve his doubt 
        before it blossomed to terror. A figure glided from the opening, 
        and the air caught in Talfus’s throat.</p>
        
        <p>The creature landed some twenty feet below the portal, 
        taking the ground as easy as stepping from a porch. He donned a dark cloak, 
        its hood pulled low, hiding his eyes. The newcomer stalked forward, and fear 
        crept through Talfus, crawling cold through his marrow on a million needle legs. 
        A wary note drifted up behind him, and Talfus knew Malo felt the same. 
        Every Durax and Berzerker they’d ever fought was a stone killer. 
        But the dark stranger approaching them now was something different altogether. 
        He didn’t so much walk as prowl, shoulders dipped, head low and still as he strode 
        smoothly across the uneven ground. The stranger moved like something on the hunt, 
        and Talfus couldn’t be sure it wasn’t so. Talfus tightened his trigger finger as the 
        cloaked figure padded closer.</p>
        
        <p>“I’m not armed, master Waadi,” growled the stranger. 
        “You mind lowering that rifle?”</p>
        
        <p>His words made a question, but his tone bristled with threat. 
        The stranger’s voice, mixed with his fearless gait, only added to 
        Talfus’s doubts as Malo stepped beside him, hooves set wide, 
        fists flexing on his hammer.</p>
        
        <p>“Again, I’m unarmed. Your commanders invited me here. 
        Please lower your weapon.”</p>
        
        <p>This time the petition was softer, and hope flickered inside Talfus. 
        <i>Maybe this</i> is <i>the Mewlatai you’ve been waiting for after all.</i> 
        “I’ll do as you ask,” said Talfus, rifle still clutched at the ready, 
        “if you’ll do something for me first.”</p>
        
        <p>“And what’s that, master Waadi?”</p>
        
        <p>“Confirm the codes for this Serum drop,” Talfus said. “And show yourself.” 
        The Waadi was no coward. He had the scars, the commendations, 
        and his new appointment as captain to prove it. 
        But his last words quivered on his tongue, 
        tripping over a knot of fear he tried hard to untangle.</p>
        
        <p>The stranger slowed to a stop. He stood still for a moment, 
        then a chuckle ground from his throat. “Of course,” he said. 
        “I’m afraid smuggling the Serum has affected my manners. 
        In my business, it pays to keep out of sight. 
        The code for this drop is X-Alpha-6-8-7-Carduss-Octum.” 
        He paused and tilted his head to the side. “How’d I do?”</p>
        
        <p>“That’s the right code,” said Talfus. “Now the cloak, if you please.”</p>
        
        <p>“Sure,” the stranger said.</p>
        
        <p>He straightened his neck and smirked, razor teeth shining white in the shadow of his hood. 
        He held out his arms, and his cloak shed from his body, 
        vanishing behind him as Talfus gasped and Malo snorted. 
        The Waadi glanced at his partner, wondering if his own eyes were as wide as the Moxen’s. 
        If so, they might fall out of his head any second. 
        Either way, he knew they were thinking the same thing. 
        <i>Do the Mewlatai have mind powers?</i></p>
        
        <p>“Hmph,” grunted the newcomer. “You flatter me. But my people don’t have that gift.”</p>
        
        <p>Talfus cocked his head, the small fins on either side of his neck going stiff as he lowered his gun. 
        “I find that hard to believe,” he said. 
        “We didn’t say a word between us, but you seem to know our thoughts.”</p>
        
        <p>“I don’t need mind powers for that,” the stranger said. “A mewling could read your faces.”</p>
        
        <p>Talfus flushed, shame burning his already-hot skin. “I’m sorry,” he said. 
        “It’s just nobody in the Dissension has seen your kind in years.” 
        But Talfus was getting an eyeful now. The stranger stood as tall as he did, 
        but all likeness stopped there. Where Talfus was long limbed and slender, 
        covered in blue scales, the Mewlatai was thick and muscled, shrouded in black fur. 
        He wore a black sleeveless jacket, one half of its front tucked beneath the other, 
        cutting a sharp V at the top that showed his chest. A pair of matching trousers 
        clung to his waist, pleated folds hanging loose around his legs. 
        Everything about him was dark, save for two things. A patch of orange fur flared 
        on his breast, a mound of purple scar cutting it in two, and his eyes shone amber, 
        glowing just this side of firelight.</p>
        
        <p>“I understand,” said the mew. “My people abandoned you long ago.”</p>
        
        <p>Talfus fought his urge to nod. “I hope you don’t mind me asking. 
        But if you can’t move things with your mind, how’d you do that?” 
        He nodded after the vanishing robe. <i>Please don’t say magic. 
        The last person I want to face down is a damn sorcerer.</i> 
        The stranger smirked, mischief curling his mouth once more, 
        and Talfus shifted nervously on his feet.</p>
        
        <p>“As you wish,” the Mewlatai said.</p>
        
        <p>He didn’t move, and Talfus looked at Malo, 
        the Moxen answering the glance with a wrinkled snout. 
        Talfus counted to himself again as the seconds scraped by. 
        And then the cloak hovered over the Mewlatai’s shoulder. 
        At first, Talfus thought the stranger was screwing with them, 
        that he’d lied about not having some kind of telekinesis, 
        or at the very least, failed to mention he had magic. 
        But then a flash of lightning unveiled his secret. 
        A fifth appendage curled from his back, coiled around the cloth. 
        Apparently, Mewlatai tails were good for more than just balance.</p>
        
        <p>“You can call me Blangaris,” he said. 
        He unclenched the cloak, and it fell to the ground behind him. 
        He leaned to the side, looking past Talfus, then folded his arms. 
        “I thought your Doctor Blink might be here. 
        Seeing how the effects of the Serum are biological.”</p>
        
        <p>“How you know Blink?” Malo said, glancing sidelong at the Mewlatai.</p>
        
        <p>“Let’s not gorulshit each other,” said Blangaris. 
        “Artemus Blink was famous before the Ellerium System fell to the Durax. 
        The leading interspecies biologist and engineer of our time. 
        Everyone knows he joined the Dissension after escaping his homeworld. 
        He’s never said his name, but I know it’s him I’ve been talking to in 
        our coded transmissions. Now where is he?”</p>
        
        <p>“Indisposed, I’m afraid,” Talfus said.</p>
        
        <p>“Ah. Does this have anything to do with the soldiers watching us?” 
        Blangaris slipped his hands from under his arms and pointed to either side.</p>
        
        <p>“How—how did you—” Talfus stuttered. 
        He hoisted his rifle to his shoulder, and Malo snorted, inching back his hammer.</p>
        
        <p>“Easy,” said Blangaris, patting the air. “I can see them.”</p>
        
        <p>“Impossible,” Talfus spat. “They can’t see this plateau except through their rifle scopes.”</p>
        
        <p>Blangaris scoffed, folding his arms again. “I imagine if we were underwater, 
        you’d be able to see, what? Five, maybe ten times farther than me?”</p>
        
        <p>“Closer to ten,” Talfus said, Waadi pride straightening his back.</p>
        
        <p>“Then I hope you can see there’s nothing sinister at work here. 
        Under the waves you’d have an edge. But above water the advantage is mine. 
        It’s just biology, master Waadi. Which is what we were talking about, wasn’t it?”</p>
        
        <p>“No, we weren’t talking biology,” said Talfus. 
        “You were asking after Doctor Blink.”</p>
        
        <p>Blangaris made a sour face. “I get it. 
        After years of supplying the Dissension with the Serum, you still don’t trust me. 
        And Artemus Blink would be a terrible asset to lose, say if he was killed. 
        Or abducted, even.”</p>
        
        <p>Talfus nodded. “Correct on every account. 
        And how can you expect us to trust you, hmm? You’ve never insisted on a face-to-face before. 
        All of our Serum drops to date have been done through runners. 
        And you weren’t exactly forthcoming when you demanded this meeting. 
        How do you know the old Serum formula is failing?”</p>
        
        <p>“A fair question, I suppose,” Blangaris said. 
        “I was on Bardeene, one of the last free spaceports in the Xenol System, 
        delivering an order of Serum to some customers. Sadly, a Durax flotilla arrived 
        shortly after we finished our transaction. The crew who’d taken the Serum and I were 
        caught in the open by two Overlords. Every one of them fell to the floor, 
        screaming from a mind-spike, and I was left to dispatch the Durax on my own. 
        Is that proof enough?”</p>
        
        <p>Talfus swallowed hard, the knot of fear that had been on his tongue 
        sliding down, twining in his throat. 
        “You killed <i>two</i> Durax Overlords? In their Irongore?”</p>
        
        <p>“Do you know anything below a General to fight without one?”</p>
        
        <p>Talfus shook his head slowly. His wonder faded and he scowled. 
        “So you’re selling the Serum to people who aren’t Dissension?”</p>
        
        <p>“Look,” said Blangaris, pinching the ridge of snout between 
        his eyes as he clenched them shut. “Making the Serum isn’t cheap. 
        Ingredients, synthesis machines, storage canisters.” 
        He flicked out a finger for each item on his list. “Not to mention 
        transportation to spaceports where runners can pick it up. It all 
        costs money. Now, I’ve been kind enough not to charge the Dissension. 
        But if my services aren’t appreciated, you and your friends can fight 
        your war without the Serum.”</p>
        
        <p>Blangaris turned for his ship, and desperation mixed with the fear inside Talfus. 
        <i>The Serum is the only thing that can even the odds against the Durax, and you know it. 
        If the Mewlatai boards his ship and disappears, all hope goes with him!</i> “Please don’t go,” 
        the Waadi said. Blangaris stopped and then turned, locking eyes with Talfus. “I didn’t mean to sound 
        ungrateful. But why did you call for this meeting? Why not just use runners to 
        deliver this new batch of Serum like all the rest?”</p>
        
        <p>“Because of the Red Wards,” Blangaris growled, a snarl twitching his lip.</p>
        
        <p>“What is Red Ward?” said Malo.</p>
        
        <p>“The Mewlatai’s greatest killers. Sworn to uphold Raijen Law and 
        protect the sacred Blood Scroll. And once they’ve taken up the hunt, 
        they won’t stop until they cut the head off their prey.” 
        Blangaris drew a dark claw across his neck.</p>
        
        <p>“Hunted by own kind?” Malo said, a sad look pulling his face long.</p>
        
        <p>Blangaris nodded. “Queen Cleondra forbid our people from the Duraxian War. 
        By helping the Dissension, I’ve broken royal decree. The Red Wards found me and I fled. 
        But not before I saw them destroy my laboratory.” The Mewlatai hung his head. “I’m here, 
        master Waadi, because I’m on the run, and the canisters on my ship are all that’s left of the Serum. 
        I wanted to be sure it reached the Dissension, just in case I’m not as 
        lucky the next time the Red Wards find me.”</p>
        
        <p>Talfus’s mouth went as dry as his skin, and he realized it was hanging open. 
        He clamped his lips, shook his head, and cursed. “But if you die, the Serum 
        dies with you. And the Dissension not long after that. And then there’ll be 
        no one to fight the Durax. They’ll rule the galaxy forever.”</p>
        
        <p>“No,” Blangaris said. “They won’t. I’ve memorized the new Serum formula, 
        and I’ll give it to you. But some of the ingredients are sourced from my homeworld, 
        and I’m not sure if you’ll be able to re-create them. <i>That’s</i> why I wanted Blink to 
        be here. So we could talk about a synthetic solution.”</p>
        
        <p>Talfus looked at Malo, and the Moxen nodded.</p>
        
        <p>“Let me call our commander,” Talfus said. 
        “I’ll tell him what you just told me. Then, hopefully, 
        get clearance to bring you inside.”</p>
        
        <p>“No,” the Mewlatai said. “I’ve lingered here too long as it is.” 
        He eyed the clouds left and right. “I need to keep moving. 
        Let’s test the Serum. Once you’re satisfied with the results, 
        I’ll give you the canisters and the instructions on how to make it. 
        And then I’ll go.”</p>
        
        <p>“But if the Red Wards catch you?” Talfus said.</p>
        
        <p>“Then I’ll be dead.” Blangaris reached inside his jacket, 
        fetching out an injector along with two vials of green liquid. 
        He loaded a tube into the device and gripped it around the handle, 
        and Talfus frowned. “Now what is it, master Waadi?”</p>
        
        <p>“How’s it work?” Talfus said.</p>
        
        <p>“Um,” said Blangaris, cocking a brow. 
        “I give you the Serum, and the Durax can’t mind-spike you anymore.”</p>
        
        <p>“That’s not what I meant,” Talfus said, 
        trying to keep any bite from his voice. “They say the Mewlatai are the only 
        race in the galaxy immune to Durax mind powers. Why is that?”</p>
        
        <p>Blangaris shrugged. “We don’t know. And neither do the Durax. 
        That’s why they’re afraid of us. They’re no match for a Mewlatai in battle. 
        Not without their terrible gift. Hence their machines.”</p>
        
        <p>Talfus shuddered. It was the second time the Durax 
        contraptions had come up. But that didn’t matter. A thousand mentions would crawl 
        his skin as many times over. “But there must be something of 
        your kind in the Serum, right?”</p>
        
        <p>“It’s fortified with our blood.” Blangaris frowned. 
        “Well, my blood, at least. And there’s a sample of that with the shipment too.” 
        He held up the injector and wiggled it. 
        “Now, I take it you’ve never had the Serum.”</p>
        
        <p>“Never,” Talfus said. “As you know, it’s always in short supply.”</p>
        
        <p>Blangaris smirked again. “Only so much blood I can give before it makes me weak.” 
        He looked to Malo. “And what about you, master Moxen?”</p>
        
        <p>“No Serum for Malo.”</p>
        
        <p>“And you’ve both faced Durax on the field?”</p>
        
        <p>“Slave Order and Soldiers, mostly,” said Talfus. 
        “There’ve been some Generals and an Overlord once. 
        But Malo and I are lucky enough to have never felt a mind-spike.”</p>
        
        <p>Blangaris nodded, his hard stare tempered with a look of respect. 
        “I’m sure luck has something to do with it,” he said. “But don’t sell yourselves short. 
        Facing the Durax with a naked mind takes more courage than even I can fathom.” 
        Talfus smiled at the compliment, his chest swelling a touch, and Malo snorted his thanks. 
        “Now, did your Doctor Blink give you something to test against?”</p>
        
        <p>Talfus slid his rifle onto his back, letting it dangle on its sling, 
        and reached into a satchel on his belt, pulling a holotab from inside. 
        Two wires hung from the device, a suction disk attached to the end of each cable. 
        Talfus pressed the pads to his skin, one near each of his temples, then swiped a 
        finger across the computer. “Doctor Blink said a Serum with higher potency will 
        show increased alpha waves in a scan.”</p>
        
        <p>“Quite right,” Blangaris said, pressing the injector 
        into the Waadi’s ribs on the left side.</p>
        
        <p>“What’re you doing?” said Talfus.</p>
        
        <p>“This’ll go faster if the Serum is injected near your heart.”</p>
        
        <p>“You know Waadi bodies?” Talfus said, 
        his jaw going loose again. “Are you some kind of doctor too?”</p>
        
        <p>Blangaris grinned. “No, master Waadi. It’s the same as before. 
        It’s <i>my</i> body. I can hear your heartbeat.” He closed his eyes and sniffed. 
        “And smell the blood pumping through it. This will all be over soon.”</p>
        Talfus wasn’t sure if the Mewlatai meant the inoculation or the beating 
        of his heart, and terror needled his spine again, cutting through to his gut. 
        He lurched back, but Blangaris snatched him by the shoulder, jamming the injector 
        into the Waadi’s torso and pulling the trigger. The device hissed, green liquid 
        draining from the vial, and Talfus cried out, in part from the stabbing needle, 
        but mostly from the fear of doubt.</p>
        
        <p>“Easy,” Blangaris said. “You’ll feel uncomfortable at first. But it’ll pass.”</p>
        
        <p>A sharp pain jabbed Talfus, like a dagger, and he clutched his chest. 
        Malo swayed back and forth beside him, an uneasy song moaning from his lips.</p>
        
        <p>Talfus wailed and staggered, his heart thrashing inside him. 
        He squinched his eyes, pawing them with one hand, clutching the holotab 
        in the other, trying to read the screen through a blur of color and tears. 
        He sucked in a shuddering breath, and the pain vanished. Talfus found his 
        footing, standing tall as warmth tingled his chest, pulsing further through 
        his body with every heartbeat. He gulped another draught of air, steadying 
        his nerves as he blinked his eyes clear, certain the computer would 
        confirm his new defenses against Durax mind powers.</p>
        
        <p class="scene-separator">***</p>
        
        <p><strong>T</strong>alfus stood strong again, and Malo stepped toward his friend.</p>
        
        <p>“His troubles are almost over,” Blangaris said, sliding between them, 
        loading another vial into the Serum injector. 
        “Your time has come, master Moxen.”</p>
        
        <p>The Mewlatai closed in and Malo stiffened, a foul stink burning his nose. 
        It reeked like rotted meat and sweat—a funk the Moxen could never forget. 
        It had choked the air of his homeworld as its fields burned and his kin screamed. 
        It was the scent of the Durax.</p>
        
        <p>Malo roared, hauling his hammer overhead. 
        He swung down and Blangaris slid aside as the bludgeon crashed the stone. 
        The Moxen spun left, pulling his weapon along behind him, howling another battle cry. 
        But the Mewlatai dodged the blow again, followed by the next, and the three after that. 
        Blangaris flashed through his sight, slipping left and right as Malo snarled and swung. 
        Then he vanished altogether. A shadow fluttered at the corner of his eye, and the Moxen 
        sent his hammer to crush it.</p>
        
        <p>He whirled a half circle, catching sight of Blangaris as he turned. 
        The Mewlatai lunged forward, snapping his forearm into Malo’s elbow with a crunch. 
        The Moxen screamed, arm hinging the wrong way on its joint as the hammer flew from his grip. 
        Blangaris snatched Malo by a horn, kicked his left knee, and pulled him to the ground, 
        pouncing on his back.</p>
        
        <p>Malo was a fearsome warrior, but Blangaris had the strength of ten Moxen soldiers. 
        And even if he somehow found a way to overpower his enemy, the fight inside Malo had 
        withered, cut from his chest along with his heart. Talfus lay a few feet off, the gills 
        on his neck unmoving like the rest of him. Blood seeped from his eyes and mouth, 
        staining the rock beneath his corpse, and Malo reached over with his good arm, 
        straining to touch his friend’s outstretched fingers. “Trusted you, Mewlatai,” he cried. 
        “Talfus trusted. So did Malo. You killed fish man. Why did you kill fish man?”</p>
        
        <p>“I’ll tell you,” spat Blangaris. “The enemy of my enemy is my friend, master Moxen. 
        And the Dissension has allied itself with the wrong mew.” A growl rumbled deep in his chest. 
        “I’m going to destroy the Serum. I’ll find its maker and tear out his fucking heart. 
        Then eat it while he dies watching. And then I’ll burn him to ash, down to the last hair on his body. 
        I’ll slaughter the eldest son of House Daelekon, master Moxen, and anyone who stands with him!”</p>
        
        <p>Malo caught sight of Talfus’s rifle on the ground and he snorted, anger, hurt, 
        and confusion twisting his insides as he lay pinned to the cold stone. 
        He had no idea who the eldest son of House Daelekon was, or why Blangaris 
        hated him enough to murder. All Malo knew was his friend was dead, and the Dissension 
        lookouts hadn’t done a thing. If they couldn’t see the ambush coming, 
        surely by now they could see the Mewlatai was no friend. Talfus had said they were 
        watching through their rifle scopes. “Why not shoot?” he sobbed. “Why leave Talfus 
        and Malo to die? Why kill fish man? Killed fish man. Malo kill you, Mewlatai. Kill you!”</p>
        
        <p>Blangaris laughed. “I believe you’d try,” he said. 
        “And I might be afraid. But I know something about vengeance you don’t. 
        It favors the mighty.” He slammed his arm into the side of Malo’s neck, 
        and the Moxen fell into darkness.</p>
      `
    },

    reviews: [
      {
        __html: `<blockquote class="review__text">
        "An explosive debut novel...leaving a trail of genre-bending destruction in its wake."
        </blockquote>
        <span class="review__reviewer-tag">
        &mdash;Jake Hasson, <span class="highlight-yellow qualification">RantingDragon.com</span>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "Fast, furious and a great deal of fun."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Antony Jones, <span class="highlight-yellow qualification">SFBook.com</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "A space opera adventure romp of the first order."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Ove Jansson, <span class="highlight-yellow qualification">Cybermage.se</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "A fun, well-paced space fantasy adventure set in a 
        universe as fascinating and diverse as it is scummy.”
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Peter Chiykowski, <span class="highlight-yellow qualification">Creator of The Story Engine Deck</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "Goes from zero to sixty in a matter of chapters...it blew me away!"
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Jessica Torres, <span class="highlight-yellow qualification">Hopelessly Devoted Bibliophile</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "An action-packed spectacle...a great time."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;<span class="highlight-yellow qualification">The Eloquent Page</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "Every turn of the page brings about a new twist, intense space battle, or a fantastic new location."
        </blockquote>
        <span class="review__reviewer-tag">
        &mdash;Jake Hasson, <span class="highlight-yellow qualification">RantingDragon.com</span>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "A fascinating and fast-paced science-fiction read, very much recommended."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;<span class="highlight-yellow qualification">Midwest Book Review</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "The female lead is an extraordinary character."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;BM3, <span class="highlight-yellow qualification">Amazon Reviewer</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "A really good sci-fi action novel, heavy on the action and intrigue."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;JeffDH, <span class="highlight-yellow qualification">Amazon Reviewer</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "[An] excellent SF read. Different aliens, magic, and fighting all in one book."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Norman, <span class="highlight-yellow qualification">Goodreads Reviewer</div>
        </span>`
      },
    ]
  },

  Decline: {
    title: 'Decline',
    series: 'The D-Evolution',
    numberInSeries: 'II',
    nextInSeries: true,
    nextBookName: 'Dishonor',
    nextBookImage: 'Dishonor.png',
    releaseDate: 'July 30, 2021 00:00:00',
    available: true,
    amazonUSLink: 'https://www.amazon.com/dp/B09BBVCKS2',
    amazonUKLink: 'https://www.amazon.co.uk/dp/B09BBVCKS2',
    appleLink: 'https://books.apple.com/us/book/id1578565028',
    koboLink: 'https://www.kobo.com/us/en/ebook/decline-5',
    bnLink: 'https://www.barnesandnoble.com/w/decline-sean-allen/1139917076?ean=2940162550488',
    bnPBLink: 'https://www.barnesandnoble.com/w/decline-sean-allen/1139914104?ean=9781953949059',
    bnHCLink: 'https://www.barnesandnoble.com/w/decline-sean-allen/1139914104?ean=9781953949066',
    image: 'DeclineWebCover_LARGE.jpeg',
    thumbImg: 'DeclineThumb.jpeg',
    imgPosition: 'left',
    textPosition: 'right',
    extract: {
      __html: `
      <h3 class="extract-header">A Hard Drink to Swallow</h3>
      
      <p><span class="smallcaps"><strong>T</strong>yrobus jogged back up the street</span> that had led him to the pier and smiled. 
      <i>You did it. You drew the sword of your will against temptation and won a great victory. 
      Father would be proud.</i> Tyrobus stopped running, panting breath as he stared at a 
      yellow shop on the left side of the street. Several glowing lanterns hung from 
      the covered porch, and a carved sign above the door read <i>Zaku</i>, just like Captain 
      Okaru had said it would. Tyrobus winced, trying not to picture the look on the old 
      sailor’s face when he tossed back the ticket. <i>You did the right thing,</i> 
      he told himself, hopping onto the stoop. <i>You honored Raijen.</i></p>
      
      <p>He pushed open the door, and a cluster of wooden shoots dangling 
      above the entrance rattled together, clunking a hollow tune. 
      Tables filled the left half of the room, stools stacked neatly on top. 
      Tyrobus stepped inside, and three mew sitting at a long counter glanced at him. 
      Two of them lost interest almost as soon as they’d looked over, 
      turning back to the drinks in their hands, but the mew closest to him held 
      Tyrobus in a hard stare. Another mew stood behind the counter, 
      drying cups with a cloth. He met Tyrobus’s eyes and bowed his head.</p>
      
      <p>“Welcome!” he said. “There’s plenty of room at the bar.” 
      He motioned to a seat with his cloth, then plunged it back into 
      the mug he was holding. Tyrobus walked to the counter and pulled out the chair. 
      Its legs honked across the floor and everyone looked again. He leaned his Kenai 
      against the half wall at his waist, sat down, and smiled politely.</p>
      
      <p>“Hmph.” The mew sitting on Tyrobus’s left—the one who’d been watching him—shook his head, 
      then tended to his cup again. He had yellow eyes, a white patch of fur around one of them, 
      the rest of his face all black.</p>
      
      <p>“Now, what can I get you?” the mew behind the counter asked. 
      He was tall and thin, with a kind face, his fur a bronze color, 
      speckled with dark brown spots, the same shade as his eyes.</p>
      
      <p>“I’m looking for my brother,” said Tyrobus. “His name is—”</p>
      
      <p>“Sorry, friend. I might know about your brother and I might not. 
      But to find out, you have to order something to drink.” 
      He frowned. “I don’t mean to be rude. It’s just handing out free advice 
      won’t keep the lanterns outside burning, that’s all.” 
      The mew with the towel smiled again.</p>
      
      <p>“Oh, my apologies,” Tyrobus said. 
      “In that case, I’ll have a cup of white blossom tea.” 
      The mew on Tyrobus’s left snickered.</p>
      
      <p>“This is a Zaku bar. No white blossom tea here.”</p>
      
      <p>“Oh—er—um. I guess, in that case, 
      I’ll just have whatever they’re having.” 
      Tyrobus looked down the counter at the other mew.</p>
      
      <p>“One Jujuntu Sweet Zaku with ginger, comin’ right up.” 
      The barkeep reached onto a shelf along the back wall, 
      removing a curved vase and a small basket. He uncorked the 
      decanter and poured the contents into a cup. He rummaged through the bin, 
      fetched out a gnarled brown root, and set it down on a board in front of him. 
      The mew twirled a knife, silver flashing Tyrobus’s eye. He smiled and set to 
      work on the twisted plant, humming a song, blade clicking against the board 
      as the barmew plied his craft.</p>
      
      <p>“There you are,” he said, setting the cup in front of Tyrobus. 
      A redbark blossom, sculpted from ginger, floated on top of the brew. 
      He leaned over and took a deep breath. The ginger had a sweet smell, 
      like flowers, but what he assumed to be Zaku tingled his nose.</p>
      
      <p>“You gonna drink that Zaku or just sniff at it all night?” 
      Tyrobus turned to the mew sitting on his left. He was dressed like 
      a shendo, with loose pleated pants and a robed vest beneath 
      his brown cloak, and his Kaiten leaned against the counter between them. 
      But he certainly didn’t act like a shendo. His speech was unrefined and 
      his manner rude. Tyrobus didn’t like the mew one bit, but he’d hold his tongue.</p>
      
      <p>“Akashi, quit pestering the kid, would ya?” 
      The mew behind the counter was drying dishes again.</p>
      
      <p>“Shut up, Ichara. I’m not talkin’ to you. 
      I’m talkin’ to the little shoshun here.”</p>
      
      <p>Tyrobus brought the cup to his lips, and Akashi and the other two 
      customers grinned. He took a big drink, bunched his face, and swallowed hard. 
      Tyrobus coughed, chest lurching as he thumped on it with his hand, hoping the 
      blows might put out the fire pouring into his belly. Akashi and the other two 
      mew in the bar crowed, laughter shaking them so hard Tyrobus thought they 
      might fall off their stools.</p>
      
      <p>“Here, kid,” Ichara said, sliding Tyrobus a big glass. 
      He snatched it up, gulping down whatever was inside without 
      knowing what it might be. Thankfully, the mug was filled with 
      ice-cold water, and it tamed the burning in Tyrobus’s chest. 
      “Don’t pay any attention to these guys. They have no joy in 
      their lives, so they have to make everyone else miserable.” 
      Ichara shot each of them an irritated glance. “And that kind 
      of a draught earns you anything I might have on this brother of 
      yours. What’d you say his name was?”</p>
      
      <p>“Blangaris,” Tyrobus choked out. He bumped his chest with his fist again, 
      hoping to coax his voice back from the dead. “Blangaris Daelekon.”</p>
      
      <p>The laughter died, and Ichara’s mouth fell open. 
      His eyes flashed to Akashi, then back to Tyrobus. 
      “Get outta here, kid. Get outta of here now!”</p>
      
      <p>“Shut the fuck up, Ichara!” barked Akashi, 
      snatching his sword as he slid off his stool, 
      “or I swear to Glane, we’ll find out if that little knife of yours 
      is any match for my Kaiten.” The barkeep scrambled back, bumping into 
      the wall behind the counter, and the two mew at the end of the room 
      circled around to join Akashi. “I just can’t believe it, boys. 
      It’s one of the mighty saviors of Raijen, in the flesh, 
      and he’s down from his pedestal next to Glane himself to 
      grace us with his presence. What, you come to town to see 
      what kind of tail your godlike status could get ya?”</p>
      
      <p>Tyrobus stood up and turned to face them. He could see they were angry, 
      but he didn’t know why. “I don’t understand,” Tyrobus said. “Is there 
      something wrong with the one I have?” He curled his tail around his side 
      and glanced at it.</p>
      
      <p>“What’d you just say, wiseass?” Akashi’s eyes flared, his right—the one flanked by black fur, 
      instead of the white patch—seeming to burn a hotter yellow than the other. 
      “Oh, boys, I don’t think this little shit knows how close he is to 
      losing his first house. And he hasn’t even made it to Raijen Do yet.”</p>
      
      <p>“Please,” Tyrobus said. “I’m sure we can resolve this honorably 
      if you could just explain why you’re so upset.”</p>
      
      <p>“Are you kiddin’ me, boy?” Akashi paused, glaring Tyrobus up and down. 
      “Shit. You aren’t kiddin’ me, are you? But that makes sense. That old mew of 
      yours was about as stiff as they came. I guess the sapling grows in the same 
      direction as the tree, doesn’t it.</p>
      
      <p>“Well, since the great Esukai Daelekon, commander 
      of the Tukshon Army, kept you in the dark, let me turn the lights on for you, hmm? 
      When the purring piles of gorulshit we call leaders withdrew us from the war, 
      our people were lost. Billions of warriors without a soul to fight, can you imagine?” 
      Akashi scoffed. “Of course, we held out hope the council would see their mistake and 
      send us back into battle. But the years passed, and as we grew restless, many of us 
      started finding new, and more profitable, uses for our skills.” He swept his cup from 
      the counter, Zaku sloshing over the side, and took a drink. “It was tough at first.” 
      Akashi slapped his glass back down and dragged a sleeve across his mouth. 
      “Mostly fighting the damn guilt demons, going against something you believed all your life. 
      And then, of course, there was getting off-world from a planet whose people wouldn’t know 
      technology from a hole in their asses.”</p>
      
      <p>“They’re ikshon—broken swords. They sell their Kaiten skills to the highest bidder.” 
      Tyrobus had almost forgotten about Ichara behind the counter.</p>
      
      <p>“I told you to keep your mouth shut,” Akashi snapped. 
      “Broken swords are exiled by the council for disobeying Raijen, 
      and they never gave us that little honor. And <i>that</i> is exactly 
      the point, isn’t it?”</p>
      
      <p>Tyrobus stared at Akashi, his face blank.</p>
      
      <p>“Ah, I forget. You’re too young to remember the days before we left the war. 
      You couldn’t fart within three hundred miles of Red Tree without worrying a Red Ward 
      might show up and take your head.” The scowl on Akashi’s face twisted harder. 
      “There wasn’t a mew alive willing to take the smallest risk against the Way. 
      And I’ll tell you something Raijen never taught me, Shoshun. No risk, no reward. 
      And I’ve grown quite fond of my rewards.” He narrowed his eyes and growled. 
      “I’ll be damned if I want to see Raijen saved.”</p>
      
      <p>Akashi disgusted Tyrobus. He’d confessed to dishonoring the Way, 
      and had Tyrobus been a shendo, with a real Kaiten at his side, 
      he would’ve cut the mew down. He would have to put Akashi and 
      the others at ease, so they’d let him go. Then he’d come back to 
      the Zaku bar with his father, once Esukai had returned from Red Tree, 
      and see if these mew chose their words more carefully. As Tyrobus thought of a way out, 
      something the mew had said kept spinning through his mind—<i>one of the saviors of Raijen.</i></p>
      
      <p>“I beg your forgiveness, Master Akashi,” Tyrobus said, bowing his head. 
      “This has all been a terrible misunderstanding, and I—”</p>
      
      <p>“Save your breath, kid.” Akashi drew his sword. 
      The two mew standing to either side of him pulled their blades, 
      then circled around to flank Tyrobus. “You didn’t really think we 
      were gonna let you walk outta here so you could tattle to your 
      daddy about how we’re shunning the Way, did ya?”</p>
      
      <p>“Holy shit!” the barkeep said. “You guys can’t just kill him!”</p>
      
      <p>“One more fucking word, Ichara. And you’re gonna end up in an off-world 
      grave without your head, just like little Shoshun Daelekon here.” 
      Akashi flicked out his blade, the tip pointing at the shopkeeper’s 
      throat.</p>
      
      <p>“I’d rethink that plan, if I was you.”</p>
      
      <p>“What the hell?” one of the mew gasped, flinching as he turned. 
      A hooded figure sat in the front of the room. All of the stools at his 
      table had been taken down, as if he expected company, and a cup rested 
      in front of him. He picked up the drink and sipped.</p>
      
      <p>“Ahh,” the stranger said. “You make a fine Zaku, Master Ichara.”</p>
      
      <p>“Who—who—who are you?” stuttered the shopkeeper. “How’d you get in here?”</p>
      
      <p>“I hope you don’t mind, but it seemed like you were finished with your drink.” 
      The stranger didn’t single any of the mew out, and they all glanced at the bar. 
      The rogue who’d been sitting farthest away from Tyrobus stared for a moment at 
      where his cup used to be.</p>
      
      <p>“How the hell did you do that?” the mew said. “Who the hell are you?”</p>
      
      <p>Tyrobus saw confusion on every face around him, but he knew exactly 
      who was taunting them. “Blangaris, don’t do anything reckless. We need to—”</p>
      
      <p>“Blangaris?” Akashi chuckled. “Well, would you look at that, boys. 
      We can cut the heads off both these pricks, squash the return of Raijen, 
      and get shitfaced drunk to celebrate—all without leaving our favorite 
      little Jujuntu hangout. This night just keeps gettin’ better.”</p>
      
      <p>“Ty,” Blangaris said, rising to his feet, “the next time some 
      assholes aren’t trying to kill us, remind me to have a little chat 
      with you about surprise and how useful it can be in a fight.” Tyrobus 
      couldn’t believe the words coming out of his brother’s mouth. He sounded 
      just as crude as Akashi. Not only that, but he wasn’t wearing the white 
      robes of a shoshun. Instead, his chest was covered in plates of black armor. 
      Blangaris swept back one side of his cloak, revealing the handle of a Kaiten, 
      and Tyrobus gasped.</p>
      
      <p>“Well, well,” Akashi gibed. “Looks like the little shoshun here 
      likes to dress up and play shendo.”</p>
      
      <p>“I’ll make you a deal, Akashi. If the three of you leave my brother 
      and the barkeep out of this, I’ll take on all of you with only that Kenai.” 
      Blangaris nodded at the wooden practice sword between the bar stools. 
      “Oh, and I promise I won’t kill you.” He gave a strange bow, 
      placing one foot behind the other as he tipped forward.</p>
      
      <p>“Boys,” Akashi said, “let’s kill this arrogant little fuck!”</p>
      
      <p>All three mew roared, charging forward with their swords at the ready. 
      Blangaris whipped his leg out from behind him, and the stool he’d been 
      sitting on flew from his foot. It crashed into the mew rushing him head on, 
      smashing his face in a shower of splintered wood and shattered teeth, sword 
      clattering from his hand as he fell limp to the floor.</p>
      
      <p>“Son of a bitch!” Akashi growled as he swung his blade sideways. 
      Blangaris launched from the ground, cartwheeled over both mew, and 
      landed behind them. Akashi turned, shuffling ahead, chopping and thrusting 
      at his foe. Blangaris folded his hands in the small of his back, stepping 
      in reverse, smiling at his attacker as he dodged every strike. He gripped 
      the Kenai still leaning against the bar as Akashi leveled a blow at his neck. 
      Blangaris arched his back over the counter, and the blade grazed the fur 
      on his throat.</p>
      
      <p>Akashi followed through, and Blangaris slammed the wooden sword down on his arm, 
      the bone snapping like a dry branch. He cried out, and Blangaris let go of the Kenai 
      with one hand, turning his body as the other rogue brought his sword down from overhead. 
      The blade chunked deep into the counter, and Blangaris swung his weapon onto the mew’s 
      wrists. He let go of his sword and dropped to his knees, howling in pain.</p>
      
      <p>“You fuckin’ piece of shit!” Akashi sliced down at Blangaris’s back, one-handed. 
      Tyrobus’s brother spun, swinging the Kenai sideways over the bar. The wooden sword 
      struck Akashi across the chest, and he sailed backward, crashing over a table and 
      stools on his way to the ground. Blangaris lowered the Kenai and looked at Tyrobus.</p>
      
      <p>“Oh, what have you done, Blangaris?” he said, his voice thin, stretched with horror. 
      “What have you <i>done?</i>” Tyrobus reached up and pulled at the fur on his jaw, shaking his 
      head as he looked at the three brigands lying on the floor.</p>
      
      <p>“I just saved your dumb ass from getting killed. That’s what I’ve done.”</p>
      
      <p>“What about Father? When he finds out—”</p>
      
      <p>“He’s not going to find out,” Blangaris snapped, “because nobody in this room 
      is going to say a damn thing to him!” He glared at Tyrobus.</p>
      
      <p>“But—”</p>
      
      <p>“Nobody, Ty!” Blangaris walked over to where Akashi lay on the ground, 
      wheezing and groaning. “Did you hear that?” he said, stooping down. 
      “We don’t want Esukai to know about this any more than you. I’m saying this 
      as a courtesy.” Blangaris leaned closer and growled. “But if you mention this 
      to anyone, or if you ever so much as think about shedding the blood of my House 
      again, I’ll rip your heart out so fast you’ll see me eat it before you die.” 
      Blangaris stood up and walked to the bar. He looked around, reached into the 
      folds of his cloak, and put a handful of gold coins on the counter. “I trust that 
      should cover everything, Master Ichara?” Blangaris looked at the barkeep, who was 
      staring at the pile of shiny metal in front of him. “Master, Ichara?”</p>
      
      <p>“Oh—oh, yes. That should be fine.”</p>
      
      <p>Blangaris sharpened his brow with disappointment.</p>
      
      <p>“Er—I mean—cover what?”</p>
      
      <p>“Very good.” Blangaris turned to Tyrobus. “There’s a gorul tied to 
      the post out front. It’s one of ours. Take it back home.”</p>
      
      <p>“Take it back home?” Tyrobus said. “That’s what you have to say to 
      me after all of this . . . this . . . I don’t even know what this was.”</p>
      
      <p>Blangaris sighed. “Now isn’t the time, Ty. Ride home and we’ll 
      talk in the morning, okay?”</p>
      
      <p>“You’re not coming with me?” Tyrobus stood there, jaw hinged open.</p>
      
      <p>“I have some things to take care of first. I’ll be home before sunrise, 
      I promise. Now go.”</p>
      `
    },
    blurb: {
      __html: `<p><span class="highlight-yellow">Technology has changed the bloody landscape of battle, 
      but all he knows is the blade. Can he claim his warrior’s birthright and still survive?</span></p>
      
      <p>Tyrobus Daelekon would cross the galaxy to be a legendary warrior like his father. 
      So when the grizzled general commits he and his twin brother to a deadly tournament 
      with alien enemies, he’s thrilled to finally seize his destiny. <span class="highlight-yellow">But he fears his sibling’s 
      willingness to break their ancient blood oath could shame their entire family and doom 
      them to ritual execution.</span></p>

      <p>Readying his blade for battle, Tyrobus is shocked when an outlaw accuses his 
      brother of theft and murder…and vanishes into thin air. <span class="highlight-yellow">And even if they manage 
      to escape punishment, he has no idea how his sword skills alone can match foreign 
      soldiers with advanced weapons and killer magic.</span></p>
      
      <p><span class="highlight-yellow">Can Tyrobus prove the power of the old ways 
      before his bloodline dies in disgrace?</span></p>
      
      <p><i>Decline</i> is the edgy second book in The D-Evolution space opera fantasy series. 
      <span class="highlight-yellow">If you like powerful legacies, richly detailed worlds, and 
      genre-bending action,</span> then you’ll love Sean Allen’s potent tale.</p>
      
      <p>Buy <i>Decline</i> to unsheathe the future today!</p>`
    },
    reviews: [
      {
        __html: `<blockquote class="review__text">
        "The way Allen builds his characters and brings them to life 
        causes you to have them living in your head for the entire book."
        </blockquote>
        <span class="review__reviewer-tag">
        &mdash;Jessica Torres, <span class="highlight-yellow qualification">Hopelessly Devoted Bibliophile</span>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "Plenty of villains, betrayals, and plot twists to keep the reader hooked."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Peter Chiykowski, <span class="highlight-yellow qualification">Creator of The Story Engine Deck</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "Sean Allen writes in a vivid style, 
        bringing the worlds and the creatures that populate 
        them to life in a stunning way."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Mikko B, <span class="highlight-yellow qualification">Amazon Review</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "A story with a good plot, held 
        together by good writing. Well worth a read."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;<span class="highlight-yellow qualification">Amazon Review</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "The character and world descriptions are vast...beautiful."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Jessica Torres, <span class="highlight-yellow qualification">Hopelessly Devoted Bibliophile</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "A very nice blend of a sci-fi setting with some fantasy elements."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Soultwister, <span class="highlight-yellow qualification">Amazon Review</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "Interesting and clever. Well written."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Yvonne S, <span class="highlight-yellow qualification">Goodreads Review</div>
        </span>`
      },
    ]
  },

  Dishonor: {
    title: 'Dishonor',
    series: 'The D-Evolution',
    numberInSeries: 'III',
    nextInSeries: true,
    nextBookName: 'Deicide',
    nextBookImage: 'Deicide.png',
    releaseDate: 'July 30, 2021 00:00:00',
    available: true,
    amazonUSLink: 'https://www.amazon.com/dp/B09BC4VBVK',
    amazonUKLink: 'https://www.amazon.co.uk/dp/B09BC4VBVK',
    appleLink: 'https://books.apple.com/us/book/id1578564415',
    koboLink: 'https://www.kobo.com/us/en/ebook/dishonor-2',
    bnLink: 'https://www.barnesandnoble.com/w/dishonor-sean-allen/1139917080?ean=2940162550495',
    bnPBLink: 'https://www.barnesandnoble.com/w/dishonor-sean-allen/1139914105?ean=9781953949097',
    bnHCLink: 'https://www.barnesandnoble.com/w/dishonor-sean-allen/1139914105?ean=9781953949103',
    image: 'DishonorWebCover_LARGE.jpeg',
    thumbImg: 'DishonorThumb.jpeg',
    imgPosition: 'left',
    textPosition: 'right',
    blurb: {
      __html: `<p><span class="highlight-yellow">Ancient ritual. 
      Violent rebuke. A bloodline feud that could drown them all in a sea of red. . .</span></p>
      
      <p>Tyrobus Daelekon is in despair. <span class="highlight-yellow">When his dishonorable twin refuses orders to 
      commit suicide and attacks their father,</span> the young warrior fears the shame will 
      destroy his family. And when he’s ordered to undertake a high-risk mission to save 
      their reputation, <span class="highlight-yellow">he’s faced with having to kill the brother whom he could never 
      best with a sword.</span></p>

      <p>Still tortured by nightmares from the blood already on his hands, 
      <span class="highlight-yellow">Tyrobus breaks the law and leaves the city</span> in search of the evidence 
      he needs to redeem their legacy. <span class="highlight-yellow">But when he encounters his sibling before 
      he’s ready, his quest may only earn him an ignoble death.</span></p>

      <p><span class="highlight-yellow">Can Tyrobus accomplish the impossible before 
      everything he knows ends in ruin?</span></p>

      <p><i>Dishonor</i> is the hard-hitting third book in The D-Evolution space opera fantasy series. 
      <span class="highlight-yellow">If you like cutthroat intrigue, shocking twists, and conflicts of the soul,</span> 
      then you’ll love Sean Allen’s thrilling tale.</p>
      
      <p>Buy <i>Dishonor</i> to put disgrace in the grave today!</p>`
    },
    extract: {
      __html: `
      <h3 class="extract-header">My Brother's Killer</h3>

      <p><span class="smallcaps"><strong>M</strong>isery clung to him</span>, and Tyrobus stumbled over the threshold to Esukai’s quarters. He felt as if the journey had taken forever, marching in silence, head down, following the trail of blood dotting the cobbles at his feet. Esukai could’ve walked off Ginju Falls and Tyrobus wouldn’t have noticed until he was falling too—the cool spray on his face, the white mist, and then nothing. <i>I wonder if that would be a better fate than what’s coming. And how did Blangaris overhear all those things about Brudenkull without father knowing? Clearly, he wasn’t making it up. What couldn’t Esukai finish on the battlefield? What the hell happened on that planet?</i></p>

      <p>Esukai smacked the button to the left of the door, and lantern light filled the room. He moved to the table at the back wall, plucking up his striker in his good hand, then pinched a rolled tinder stick between thumb and forefinger on the other, grimacing as he sparked a flame. The general tilted a candle and touched the punk to its wick. He set it down and moved on to the next.</p>

      <p>Tyrobus stood there, lost on the sea of his emotions, watching numbly as his father lit candles in a room already doused with light. His thoughts found shore, and he wondered how badly Esukai’s hand hurt, and if he should fetch a shaman. Esukai had cut strips of cloth from his robes back in the courtyard, grunting as he stared at his fingers in the grass and cinched the fabric on his wounds. Tyrobus had asked about the digits as the general marched for the palace. “Leave them,” his father had said. “Let the birds and worms take what I could not keep.” His thoughts drifted away again, carried by a current of pain, rage, and sadness.</p>

      <p>“Close it!” Esukai said. Tyrobus turned and pulled on the door. It met the jamb, and he heard the general’s Kaiten slide from its sheath. His heart lurched in his chest and Tyrobus froze. “Face me.” Tyrobus moved slowly, not from exhaustion as before, but from fear, wondering if Esukai had decided that both his sons were dishonorable, that the Daelekon line had failed. A new current swept through the ocean of anguish inside him. It was guilt. <i>You should have moved faster! It should have been you and not Kristük who kept Blangaris’s blow from killing Father!</i> Tyrobus faced his shendo once more and raised his head.</p>

      <p>Esukai stood there, sword drawn, staring at him, as if trying to decide his fate. Live or die. The general spun around, slashing backhanded as he went, and the lantern above the table shattered and blinked out. Shards of green glass tinkled across the floor, then crunched as Esukai walked to the adjacent wall and gave the bulb there more of the same. Esukai marched toward Tyrobus. His father cut at the lantern near the door, and Tyrobus flinched, turning his head as bits of glass prickled his face and arm. The general stepped past Tyrobus, glaring as he went by.</p>

      <p>Esukai extinguished the last lantern and then walked back to the table. He gripped a candle, a wince twitching his eye as his lone finger now did the work of four. Esukai set the waxen torch on the floor and took a step back. “Kneel,” he said, pointing beyond the candle with the tip of his blade. Tyrobus trudged to his mark and lowered himself onto his knees. “I will only ask you once to look me in the eyes.” Tyrobus lifted his head. “How long have you known of this?”</p>

      <p>“Of what, Father?” Tyrobus said.</p>

      <p>“Do not claim ignorance with me, boy!” snarled Esukai, his features sharp and dangerous in the flickering light. “He is your brother, your twin. You share a connection that few mew have ever known. Now, the next words from your mouth better ring true . . .” Esukai didn’t finish the threat. He didn’t have to. His Kaiten curved from his hand, hovering off to Tyrobus’s right.</p>

      <p>“Blangaris . . .” Tyrobus paused. “He’s been going out at night for several years now. I think it began when you started giving us time to ourselves after dinner.”</p>

      <p>Esukai stared at him, unblinking. “How do you know this?”</p>

      <p>Tyrobus shifted on his knees. “Um—well—er—”</p>

      <p>“Out with it!”</p>

      <p>“We used to . . . talk to each other from our windows, after your lantern had gone out. One night he didn’t answer.” Tyrobus waited for the blade to flick over and loose his head from his shoulders. But it didn’t move, nor did Esukai’s expression.</p>

      <p>“And?”</p>

      <p>“And it went on,” said Tyrobus. “The next night, then the next, and the one after that as well. Finally, I found enough courage to check on him.” Esukai arched a brow. “He wasn’t there. Going forward, I’d look in on him now and again. Sometimes he was there, sometimes he wasn’t.”</p>

      <p>“And where would he go?”</p>

      <p>“I don’t know,” Tyrobus said. Esukai growled. “I—I don’t know, Father, I swear it! I always wanted to hide myself in the courtyard and wait, so I could follow him, but I was afraid you’d catch me.”</p>

      <p>“So you never saw him committing an act that violated Raijen Law?”</p>

      <p>Fear choked him, and Esukai must’ve read his face. The general stepped forward, a low growl threatening from between broken and bared teeth. He twisted his wrist so the razor edge of the Kaiten faced Tyrobus. “I’m—I’m sorry, Father. I didn’t know what to do. He was my friend . . . he was my only friend. He was my brother.” Tyrobus hung his head and wept.</p>


      <p>“Stop sniveling like a mewling whelp!” Esukai roared, whipping his blade within an inch of his son’s cheek. “Tell me what you witnessed, and if I see another tear, I’ll cut your eyes from your head!”</p>

      <p>Tyrobus sucked a breath. Esukai’s words, and the steel of his tone, sliced deeper than any Kaiten ever could. He quickly brushed his fingers over his eyes, praying to Glane he could hold back the flood of tears Esukai had just set loose. His heart pounded so fast, Tyrobus thought it might burst through his chest. He swallowed and sniffed, pulling the tear-borne snot down his throat before it ran bubbling from his nose. It tasted of sorrow and salt.</p>

      <p>“The night you left to announce us ready for Raijen Do,” said Tyrobus, “Blangaris disappeared. I waited for him to come back in the morning, but he never showed. On the evening of the ninth day, something told me I’d find him in Jujuntu, so I went.”</p>

      <p>“And you found him there?”</p>

      <p>“Not at first,” Tyrobus said. “It was late, and most of the town had shuttered their windows for the night. I saw a skyjunk overhead and followed it to the peer.” Esukai frowned. “A mew asked me why I was there so late, and I told him. He checked the ship’s passenger list but didn’t see Blangaris’s name, so he told me I should ask the local barkeep.”</p>

      <p>“And so you went there?” said Esukai. “To the Zaku bar in Jujuntu?” Tyrobus nodded. “And that’s where you found Blangaris.”</p>

      <p>“Not exactly,” Tyrobus said. “It’s more like Blangaris found me.” Esukai cocked his head. “I went inside and told the barmew I was looking for my brother—Blangaris Daelekon. There were three mew there who recognized our House. The barmew told me that they sold their swords to the highest bidder.”</p>

      <p>“Mercenaries,” Esukai spat. “Murderers.”</p>

      <p>“The leader, a mew called Akashi, said he liked his new life without Raijen, and he never wanted to see it return to Arukael.” Tyrobus paused, staring into that night at the Zaku bar. It seemed like a lifetime ago.</p>

      <p>“And then?”</p>

      <p>“And then they tried to kill me.” Esukai blinked, the anger on his face giving way to surprise for a moment before settling back in. He lifted his brow, bidding Tyrobus go on. “And then Blangaris was there. One moment he wasn’t and the next he was, telling Akashi and his mew to rethink their plan. Blangaris gave them a chance, I’ll give him that.” A faint smile tried lifting the corners of his mouth and Tyrobus fought it back, lest Esukai slice the lips from his face. “Of course, they didn’t listen.”</p>

      <p>“Blangaris killed them?” Esukai tightened his grip on the sword.</p>

      <p>“No, no!” said Tyrobus. “He was wearing armor and he had a Kaiten. But he didn’t need any of it. He thrashed them all with my Kenai, then threatened to kill them if they ever told anyone.” <i>Actually, he threatened to eat their still-beating hearts, but let’s not get hung up on the details, shall we?</i></p>

      <p>“Is there anything else?”</p>

      <p>Tyrobus searched his memory. “He had jin,” he said after a moment. “Lots of it. He always has lots of jin.” Esukai didn’t respond, and the silence smothered Tyrobus like a hot blanket pulled tight across his face.</p>

      <p>“How could I have been so blind?” Esukai finally said, staring through Tyrobus. “I did as my father did, and his father, relaxing the rules in your ninety-first year, letting you apply your training, without a shendo peering over your shoulder at every turn. How did I not hear my own cub slipping in and out of my home? Have my senses dulled so in this late house? Katuka, my love, I failed you. I did not raise our sons well, as you had wished. I failed. I’m sorry.”</p>

      <p>“Father, no!” said Tyrobus. “Blangaris is a master at sneaking. He—”</p>

      <p>Esukai roared, snatching him up by the neck and hauling Tyrobus backward. He gave a sickly breath as he slammed the wall, the general’s mangled hand pressed to his throat, the tip of his sword pricking Tyrobus under the chin. “Ninety years,” Esukai growled. “For <i>ninety years</i>, I taught you the Way. Honor above all else—above all else! Above your heart’s desires. Above your father and brother and the loss of your mother. There is none higher than almighty Glane and Raijen!</p>

      <p>“You should’ve told me. When I arrived home from Red Tree, the first words from your mouth should’ve laid bare your brother’s betrayal. <i>You</i> are partly to blame for this!” Esukai’s finger and thumb tightened on his neck, and Tyrobus smelled the metal tang of fresh blood. “If you had, we’d have been rid of that demon coward, and House Daelekon wouldn’t be crumbling before all of Red Tree!” He squeezed harder, and Tyrobus gagged. “Do you see that? Do you?” Esukai waited. He pushed his Kaiten into the soft underside of Tyrobus’s jaw, where it broke the skin, a thin line of crimson gliding down its edge. Tyrobus inhaled.</p>

      <p>“I do, I see it, I see it, I’m sorry!” he choked out. Esukai let go, and Tyrobus clutched his neck, gasping for air. The sea of emotions inside him had dried up and vanished, replaced by something far more vast, far deeper and darker. He was empty. Tyrobus had never felt so alone in his entire life, and he ached to tumble to the ground, screaming and sobbing. But he knew if he wallowed, even for a moment, Esukai would cut off his head.</p>

      <p>“I’m glad you take responsibility for your part in this mess.” Esukai sheathed his blade. “And I shall take mine. Together we will salvage what honor remains in House Daelekon and build on its foundation.” Tyrobus wrinkled his brow. “<i>You</i> will lead our House.”</p>

      <p>“I—I have increased my Ryukura practice, Father,” said Tyrobus. “I will fight my best in Raijen Bru, like a true warrior of Glane.” Esukai folded his arms and stared at him for what seemed like an age.</p>

      <p>“There are laws of Raijen,” he finally said, “passed from the previous head of the House to the next as they assume leadership. And since you will be the head of House Daelekon, I am telling you now. Kaijin-ga permits—” Esukai froze, his eyes locked on the shadows over Tyrobus’s shoulder. He walked to the corner of the room near the door, drawing his blade as he went. The old general cut through the dark, his sword whispering into the air. He looked to the corner down the wall, then up. “This castle has phantoms. I can feel them.”</p>

      <p><i>Or it’s the drifter, Bogue, turned invisible. Or maybe even the thing lurking beneath the castle, whatever that might be. Maybe it can go unseen too.</i> Tyrobus shuddered, wondering if he might die in this room after all.</p>

      <p>Esukai moved toward the center of the chamber once more, his eyes darting left and right as he walked. “Kaijin-ga allows the leader of a House to refuse relinquishing his station to an heir, if he can prove that heir has dishonored the Way.”</p>

      <p>“If Blangaris wins Raijen Bru,” Tyrobus said, but his mind whispered, <i>When Blangaris wins Raijen Bru</i>, “you’ll go to the High Council and tell them what he did in Jujuntu?”</p>

      <p>“No,” said Esukai. “Jujuntu happened outside of Raijen Do, and doesn’t fall under the purview of Kaijin-ga. I’m afraid I made the only move I could. And I failed, my son.” The wrinkles on Tyrobus’s brow sprouted wrinkles of their own. “As leader of House Daelekon, it is my duty to discipline my shoshuns. Had I struck down Blangaris, I would have been taken before the council, where I would’ve explained my actions. Given Chael’s dishonorable alliance, it’s likely I would’ve lost my head. But your hands were clean. You would’ve been left whole.” Esukai pushed out a breath. “But soon Red Tree will know there is sour blood between myself and Blangaris.”</p>

      <p>Tyrobus tilted his head. “How would they?”</p>

      <p>“My son, Raijen might be dying, but the courtyards around Noble Tree weren’t empty. There were mew in the distance who might’ve been curious about the commotion and investigated the scene after we left.”</p>

      <p>“I didn’t see anyone, Father.”</p>

      <p>Esukai scowled again, and Tyrobus dipped his chin, cursing himself for opening his mouth. “Maybe not,” his father said. “But there’s also Chael, who you know has the city under surveillance with his unholy devices. And even if neither one of those things come to pass, the outlanders won’t keep quiet.” Esukai paused. “Well, perhaps Master Djeck will respect our private affairs. But the Ironak will have no such discretion—he cares only for himself. The rip in House Daelekon will soon be common knowledge, trust me. And then what I say to the council won’t matter, regardless of any evidence I might have of your brother’s treachery. My testimony is tainted. They’ll see it as vengeance for what has happened here tonight. And they would not be wrong.”</p>

      <p>Tyrobus gazed at the ground, turning Esukai’s words over in his head. “I don’t understand. If the council won’t believe your testimony, then how will you claim Kaijin-ga?”</p>

      <p>“I won’t,” Esukai said. “<i>You</i> will invoke it.” Tyrobus lifted his head, his mouth falling open. “Well, not Kaijin-ga exactly. But you will ask the High Council to apply its principles to Raijen Bru.”</p>

      <p>Tyrobus’s confusion disappeared like mist at dawn, and the clarity was like a razor, slicing him to pieces. He knew what he had to do—two seemingly impossible things, one of which his father, among the greatest Mewlatai warriors who ever lived, had failed to do on this very night. “But that means . . .”</p>

      <p>“That means”—Esukai gripped him by the shoulder—“you must first track Blangaris and find evidence of him breaking Raijen Law.”</p>

      <p>“I—I can’t—track—he’s like a ghost!” The words fumbled from Tyrobus’s mouth.</p>

      <p>“Find him!” Esukai said, tightening his grip. “Now, what is the longest stretch we’ve had between battles in the arena, three weeks?” Tyrobus nodded. “Three weeks . . . that is hardly enough time to get anywhere meaningful off-world and back again. And then he’d need a starship, and those aren’t easy to come by so close to the capital. No, he’s up to something on Arukael. And you, my son, must find out what it is.” Tyrobus stared blankly at Esukai. “It cannot be something trivial. Being caught outside the walls of Red Tree won’t be enough. It must be grievous. But knowing Blangaris, that won’t be a challenge. And it can’t be just your word. You must have something tangible, an artifact or”—Esukai looked away, his eyes searching the shadows for an elusive thought—“a Red Ward as witness.”</p>

      <p><i>Impossible task number one,</i> Tyrobus thought. <i>Oh glane-on-a-gorul, how does he expect you to hunt down Blangaris when he couldn’t even catch him sneaking around the house for ten years? And that’s not the worst, is it? Nope. Look at him, you can see it in his eyes. Here it comes, impossible task number two.</i></p>

      <p>“Then, my son.” His father paused.</p>

      <p><i>As if this next thing needs any more drama sprinkled on top.</i></p>

      <p>“You <i>must</i> defeat Blangaris in Raijen Bru.” Esukai shook Tyrobus by the shoulder as he spoke. “And once he’s fallen, you’ll address the council, right there in the arena. You will invoke Kaijin-ga, and present your evidence. And should the High Council find Blangaris guilty of his sins, the Red Wards will burn his body. And then we will—” Esukai growled, shoving Tyrobus backward and loosing his Kaiten. “Don’t shake your head at me, boy!”</p>

      <p>Tyrobus hadn’t realized what he was doing, but now that Esukai had pointed it out, he wasn’t surprised. He’d tried to follow his twin once as he sped away from Kiko’s in a shuttle, but it was like his brother had turned into the wind itself and floated off into the night. And his sword? Tyrobus had just watched father attack Blangaris, without a damn thing in his brother’s hand, mind you, and if it hadn’t been for Shoshun Günnarryn, the general would be in his last house.</p>

      <p>“Listen well!” Esukai said, pointing his sword at Tyrobus again. “If you deny me this, I will split you in half where you stand. Then I will spill my life on the stone. For there will be <i>nothing</i> left of House Daelekon if that bastard creature should win leadership of this clan. Do you hear?” Tyrobus stared back, his emotions swirling into a maelstrom. “This is it. No more chances, no more hoping that your brother will see his folly and come to Glane. Blangaris your brother is gone, and an enemy is all that remains, an enemy that will destroy our line. Now I saw my son, not two days ago, charge into the arena, risen by the grace of Glane, the thunder of the Blood God himself in his veins. He vowed to fight for all true Raijen mew on Arukael and to restore the honor taken from House Daelekon. I ask you, are you that mew, or did you not speak from the heart? Either you follow the Way or you do not, Tyrobus. Now choose!”</p>

      <p>Esukai’s words slammed into him, and Tyrobus staggered back. He stared at the aging warrior’s face. It was set with something beyond anger or hatred. It was everything, his very existence, all of his being channeled into one certainty—Blangaris must die, or he and Tyrobus must perish in the effort. And he was right. This was their way of life, and Blangaris had turned his back long ago, never to return. Tyrobus took a breath so deep he thought the skin on his chest might split apart. He let the air leak out, slow and steady. “I choose the Way, Father. I choose House Daelekon.”</p>

      <p>Esukai lowered his head and his shoulders relaxed. “I’m pleased to hear you say it,” he said, sheathing his blade. “I’m sorry this burden falls on you, my son. And I regret that I can only help you with the sword. As I said, the evidence of Blangaris’s dishonor must be gathered and presented by you and you alone. Do you understand?” Tyrobus nodded slowly. “And one more thing. You will no doubt find yourself outside of these walls. If you get caught before Raijen Bru, we will all die. This is a risk, my son. The council might not even accept your claim to Kaijin-ga, and we’ll lose our heads. But I will gladly bear the shame if it means knowing that demon does not decide the fate of this House.”</p>

      <p>“But won’t Chael and his allies deny my claim, no matter what I prove?”</p>

      <p>“I think we stand a good chance,” said Esukai. “Kaijin-ga is Blood Law, similar to the Kaijo that Captain Krün tried to invoke when Blangaris was caught on Maru Street. That means the Captain of the Red Wards holds a vote. Chael and his alliance will carry four nays against your claim, but from what I’ve seen, the rest of the High Council still upholds Raijen. They will give us three yeas.”</p>

      <p>“And Krün’s vote will bring us even,” Tyrobus said, nodding. “And then what?”</p>

      <p>“Kaijin-ga is Blood Law. Therefore, the Red Wards are the final authority. In the event of a tie, the captain’s lieutenant breaks the draw. I have seen Ileana of House Pintarus, and my heart tells me that she will be on the side of Raijen. Glane willing, you’ll be granted Kaijin-ga and the rotted limb cut from our House.”</p>

      <p class="scene-separator">***</p>

      <p>Tyrobus’s journey back to his room was unclear. He remembered cobbles at his feet, a blur of greenish-white light at the corners of his sight, and a fathomless hole of deep black in front of him. The trip seemed nearly endless, and now that he thought about it, he might’ve taken a wrong turn, meandering through the halls of the palace in a fog of woe. But now he lay in the small washroom attached to his quarters. <i>These are my quarters, aren’t they?</i> Tyrobus thought. And then decided he didn’t care.</p>

      <p>He clutched the commode, its metallic rim cool on his cheek. His body heaved, and he retched into the bowl. He slid his hand up and pressed the button on the tank, and the contraption gurgled and whooshed. This was one device that Tyrobus could appreciate. He recalled the outhouse on their estate. Hardly more than a hole in the ground, with walls. Just relieving yourself was a gag-inducing event. Getting sick inside was a miserable, drawn-out affair, the stink coaxing its own bouts of spasms and vomit, in addition to whatever ailment delivered you there in the first place. <i>I wonder if Esukai has even stepped foot in his washroom. He probably ran his sword through everything inside.</i></p>

      <p>Tyrobus gave a dazed chuckle, picturing his father sprinting all the way to First Level and through the city gates just to piss. But his mind jumped back to the outhouse, then home, and then to his brother. Tyrobus saw him clearly, leaning on his Kenai beneath the redbarks in front of their house. His eyes danced with mischief as he laughed, smiling back at Tyrobus. It wasn’t the arrogant grin that seemed carved on his face for the last several years, but a genuine look, full of joy, love, and brotherhood.</p>

      <p>Tyrobus pulled himself forward as he retched again. He pressed the button and watched the water swirl and then vanish, praying to Glane that the memory, and all those like it, would wash away and disappear along with the foul soup. Those memories would have to die. Tomorrow, Tyrobus would set out to prove Blangaris’s disloyalty. Tomorrow, he had to start killing his brother.</p>

      <p>He staggered into the main room and collapsed on his bedroll. Tyrobus glimpsed a blanket folded on the floor next to the bed, with an odd piece of brown fabric peeking from its center. <i>Shit. Braelkor’s serum. Well, at least you know you’re in the right room.</i> His eyes slid closed, and Tyrobus drifted off, his last thoughts a prayer, begging Glane for a dreamless sleep.</p>
      `
    },
    reviews: [
      {
        __html: `<blockquote class="review__text">
        "Allen has a dazzling capacity for writing gripping, visual 
        action scenes that push his characters to their physical 
        and mental limits.” 
        </blockquote>
        <span class="review__reviewer-tag">
        &mdash;Peter Chiykowski, <span class="highlight-yellow qualification">Creator of The Story Engine Deck</span>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "The characters are amazing . . . and how they’re described makes me want 
        to jump into the book and see for myself."
        </blockquote>
        <span class="review__reviewer-tag">
        &mdash;Abby Flores, <span class="highlight-yellow qualification">Bookshelf Confessions</span>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "Zips along at a breakneck pace."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;<span class="highlight-yellow qualification">The Eloquent Page</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "Allen's style of writing is truly superb and I often felt 
        like I could really envision what was going on."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Kate, <span class="highlight-yellow qualification">Goodreads Review</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "Lots of action, heroes and villains . . . I really enjoyed the ride."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;<span class="highlight-yellow qualification">Amazon Review</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "Allen does an excellent job of weaving multiple story lines.”
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Nicole, <span class="highlight-yellow qualification">Goodreads Review</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "The characters were nicely developed and the descriptions of 
        both the setting and the action scenes were well detailed."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Soultwister, <span class="highlight-yellow qualification">Amazon Review</div>
        </span>`
      },
    ]
  },


  Deicide: {
    title: 'Deicide',
    series: 'The D-Evolution',
    numberInSeries: 'IV',
    nextInSeries: true,
    nextBookName: 'Downfall',
    nextBookImage: 'Downfall.png',
    releaseDate: 'April 15, 2022 00:00:00',
    available: true,
    amazonUSLink: 'https://www.amazon.com/dp/B09Y2CJ6NV',
    amazonUKLink: 'https://www.amazon.co.uk/dp/B09Y2CJ6NV',
    appleLink: 'https://books.apple.com/us/book/deicide/id1619540002',
    koboLink: 'https://www.kobo.com/us/en/ebook/deicide-4',
    bnLink: 'https://www.barnesandnoble.com/w/deicide-sean-allen/1141359596?ean=2940160917429',
    bnPBLink: 'https://www.barnesandnoble.com/w/deicide-sean-allen/1141376437?ean=9781953949134',
    bnHCLink: 'https://www.barnesandnoble.com/w/deicide-sean-allen/1141376437?ean=9781953949134',
    image: 'DeicideWebCover_LARGE.jpeg',
    thumbImg: 'DeicideThumb.jpeg',
    imgPosition: 'left',
    textPosition: 'right',
    blurb: {
      __html: `<p><span class="highlight-yellow">The last of his line. 
      A deadly plot to destroy his legacy. Can he hunt down the answer 
      before all is lost?</span></p>
      
      <p>Tyrobus Daelekon doesn’t have time to grieve. With both his 
      father and twin brother gone, <span class="highlight-yellow">the young warrior finds himself 
      falsely accused of planning to murder the world’s leaders.</span> 
      So after barely escaping brutal assassins, the desperate sword 
      fighter sets out to prove his family’s innocence.</p>

      <p><span class="highlight-yellow">Dezmara Strykar’s emotions are on a knife’s edge.</span> Sensing Tyrobus 
      is hiding something important, she struggles to understand how they are 
      connected. And suspecting she may be the only human left alive in the galaxy, 
      <span class="highlight-yellow">she’s losing hope of ever unraveling the secrets of her origins.</span></p>
      
      <p>As Tyrobus teams with a pair of untrustworthy rogues, <span class="highlight-yellow">his journey to a 
      mysterious destination puts him at the mercy of unstoppable beasts and a 
      life-threatening conspiracy.</span> And with Dezmara still healing from battle, 
      she fears another confrontation with vicious enemies will be her last.</p>
      
      <p><span class="highlight-yellow">Can these two fugitives stand strong against their mortal foes, 
      or does the real danger come from each other?</span></p>

      <p><i>Deicide</i> is the gripping fourth book in The D-Evolution space opera 
      fantasy series. <span class="highlight-yellow">If you like dark schemes, 
      gritty action, and hair-raising twists and turns,</span> 
      then you’ll love Sean Allen’s bold quest.</p>

      <p>Buy <i>Deicide</i> to fight the way to freedom today!</p>`
    },
    extract: {
      __html: `
      <h3 class="extract-header">A Little Blood</h3>
      <p>“Go,” Kaela roared. But the fight was over before her lips had come together again. 
      The second Kaela opened her mouth Dezmara had darted across Kaelth’s body, raking her 
      sword diagonal across his torso, followed by a sideways chop to the base of his spine. 
      She glimpsed the edge of the sparring ring to see Simon standing up from the bench, 
      staring wide-eyed, his jaw slack.</p>

      <p>“Glanedammit,” roared Kaela. “Did I wrong you, Kaelth? Are you trying to shame me?”</p>

      <p>Kaelth reached across his body, hooking the hand guard of his Kenai around the back of his lame arm. 
      “What are you asking, sister?” he said.</p>

      <p>“You know damn well what I’m asking,” Kaela snapped. “Are you losing on purpose just to spite me? 
      Is it because I told these outlanders you don’t trust them?”</p>

      <p>“I am fighting just as hard as when I spar with you or Shendo Tyrobus, I swear it on our House.”</p>

      <p>Kaelth’s eyes swelled with hurt, and it stabbed Dezmara’s heart. What she wouldn’t give to know her own blood, 
      to talk with them, laugh with them, to love them.</p>

      <p>“I’d say you’re out of excuses,” Dezmara said, turning to Kaela. “You’re up. Unless all of your bitching and 
      moaning and barking orders has made you tired. Do you need to go lie down? Should I call on you tomorrow?”</p>

      <p>Kaela growled, spinning round. She snatched out another Kenai from her bundle, letting the silken cloth 
      fall to the dirt as she turned and charged, roaring murder.</p>

      <p>“Oi,” Simon shouted. “Look out, Dezzy!”</p>

      <p>Kaela barreled past her brother, and he stumbled away, shouting a protest as she chopped down from overhead. 
      Dezmara caught the strike and seven more besides, streaking from the left, sailing from the right, chopping high 
      and low as she retreated. Kaela was better than her brother by far, her strikes faster, harder, and more precise. 
      Dezmara caught another blast close to her chest, and she pushed back with all her strength as the mew leaned in 
      and snarled.</p>

      <p>“Some might call charging an opponent when she’s not ready a dirty trick,” 
      Dezmara huffed. “Not...very...Mewlatai of you.”</p>

      <p>“Haven’t you been paying attention to Master Daelekon?” Kaela grunted. 
      “A true warrior is always ready for battle.”</p>

      <p>Dezmara shoved hard, then filled the space between them with her sword, flicking it every which way in a blur of 
      tan wood. Their Kenai snapped together as Kaela countered, the thundering cracks stealing the birdsong drifting 
      through the canopy, hushing the sea of chirping insects marching through the brush. Kaela blocked a strike and 
      lunged, whipping down from overhead once more. Dezmara planted a foot on the mew’s leg and bounded upward, deflecting 
      the blow as she sailed. She turned in mid-air, snapping a kick across Kaela’s face, and the mew cried out, stumbled, 
      then found her footing again.</p>

      <p>Kaela cocked back her Kenai and swung, hard and high as her opponent touched down. 
      Dezmara flinched, lifting her arm at the last second, and the blow glanced off her armor, skimming the side of her 
      head. Needles of pain stabbed her skull as crackles of light forked her vision. She could feel blood streaming between 
      the roots of her hair, pouring onto her face as she raised her sword to counter. Kaela heaved up and they stepped 
      toward each other, the both of them crying out.</p>

      <p>“What was that fucking shite?” Simon yelled, dashing between them, with Diodojo and Kaelth close on his heels. 
      The fighters lowered their swords, and Kaela turned, walking back toward the bench with her cloak on it. “You bloody
      lot don’t swing for the head, Kaela. Ty told me as much. You got no masks or hoods or bloody fucking nothing up 
      there. That blow could’ve killed her!”</p>

      <p>“Calm down, Simon,” said Kaelth, grasping his shoulder.</p>

      <p>“Don’t bloody touch me,” Simon growled, jerking his arm away. “It was a dirty cut, and you fucking know it.”</p>

      <p>“You forfeit the bout,” Kaela said, kneeling at the bench, her back toward the ring. “The match is mine.”</p>

      <p>“How do you figure?” Dezmara said.</p>

      <p>“Your friend interfered. The match is mine.”</p>

      <p>“Then we’ll have another round.”</p>

      <p>“You lost, Dezmara.” Kaela picked up the swatch of blue silk from the ground, smoothed it on the bench, and 
      laid her Kenai in the middle. “You should have Doctor Blink tend to that wound on your head.”</p>

      <p>“Oh, I get it,” Dezmara chuckled, dabbing at the blood trickling her ear. “I have to jump through hoops to 
      prove I’m worthy to fight you. But you—miss queen-shendo-of-the-sparring-pit—you get to take a cheap shot, then walk 
      away because you’re in charge, huh? Sounds like you’re afraid of not being the best warrior here while Tyrobus is away. 
      Sounds like you’re a coward.” Simon screamed another warning, Doj howled, and Kaela shrieked, spinning round with her 
      Kenai at the ready, rushing straight on.</p>
      
      <p>Dezmara knew this was coming. She could feel Kaela’s anger rising from the 
      moment the Sujaros had met them at the hangar, ragged and pulsing, on the edge of frenzy, and she knew if she pushed, 
      the Mewlatai would lose control. She didn’t like being treated like shit, and she <em>really</em> didn’t like it when people 
      messed with her friends. Dezmara had been counting on her anger over their mistreatment and the sting of her wound to 
      give her the edge this time, but she didn’t need it. Something had changed. Somewhere deep inside a switch had been thrown.</p>

      <p>Time slowed to a crawl. She heard her breath, sliding calm and easy through her body. The other sounds near her 
      droned out—the buzz of the jungle, the shouting, the howling, the shrieking, all stretching long as an 
      invisible mire clung to everyone and everything around her. But not Dezmara. Her body glided over the dirt, free 
      and fast and humming with more strength than she'd ever known. Dezmara sprinted headlong, raising her Kenai as she 
      charged. She leapt, twirling through the air, roaring a battle cry and chopping with all her might as she rounded 
      full-circle. Her sword smashed across Kaela’s chest, cracking like a tree snapped in a storm, breaking apart 
      in a shower of splinters and jagged edges. The blow wrenched the Kenai from Kaela’s hands and she squawked, feet 
      whipping out from under her, sailing skyward as she flipped toes over head. She crashed face-first to the ground 
      and lie still, a groaning pile of fur and armor.</p>

      <p>“For glanessake,” Kaelth said, cutting Dezmara a wide berth as he sprinted to Kaela’s side. He knelt down, 
      gripped her gently on the shoulder, and rolled Kaela onto her back, moaning and gasping for breath. “It’s okay. 
      You’re okay.” He placed his hand on her brow and glared up at Dezmara. “How did you—I didn’t see—what did you do?” 
      Kaelth glowered, his chest heaving with angry breaths. “I asked you a question.”</p>

      <p>“I caught that,” Dezmara said. “But I want your sister to hear the answer.” She waited for Kaela to unclench 
      her eyes, then Dezmara drug her hand through the matted hair on her head. “You wanna know why I just broke a Kenai 
      across your blood, huh?” She jabbed out her red palm. “There’s that for starters. But I would’ve done it without 
      this nasty little swipe at my head.” Kaelth scowled at her. “I get you all not trusting us. Hell, I wouldn’t trust 
      somebody telling my story, not as far as I could throw her. But I have news for you. We don’t know much about you 
      either, except you’re outlaws on your world and wanted by the people who run the place. The only souls I trust are 
      standing right there.” Dezmara motioned over her shoulder with her head. “But just because I don’t trust somebody, 
      doesn’t give me the right to treat them like trash. Your sister insulted me, she insulted my family. 
      That’s why I did what I did.”</p>
      `
    },
    reviews: [
      {
        __html: `<blockquote class="review__text">
        "[Allen has] . . . relentless energy and intelligent, well-written prose."
        </blockquote>
        <span class="review__reviewer-tag">
        &mdash;Antony Jones, <span class="highlight-yellow qualification">SFBook.com</span>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "Fast, furious and a great deal of fun."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Antony Jones, <span class="highlight-yellow qualification">SFBook.com</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "A space opera adventure romp of the first order."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Ove Jansson, <span class="highlight-yellow qualification">Cybermage.se</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "Goes from zero to sixty in a matter of chapters...it blew me away!"
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Jessica Torres, <span class="highlight-yellow qualification">Hopelessly Devoted Bibliophile</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "An action-packed spectacle...a great time."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;<span class="highlight-yellow qualification">The Eloquent Page</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "A fascinating and fast-paced science-fiction read, very much recommended."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;<span class="highlight-yellow qualification">Midwest Book Review</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "Described in such detail it felt like watching anime in my brain."
        </blockquote>
        <span class="review__reviewer-tag">
        &mdash;Jake Hasson, <span class="highlight-yellow qualification">RantingDragon.com</span>
        </span>`
      },
    ]
  },

  Downfall: {
    title: 'Downfall',
    series: 'The D-Evolution',
    numberInSeries: 'V',
    nextInSeries: false,
    nextBookImage: 'V6LogoYellow.svg',
    nextBookName: null,
    releaseDate: 'April 15, 2022 00:00:00',
    available: true,
    amazonUSLink: 'https://www.amazon.com/dp/B09Y7CT2XW',
    amazonUKLink: 'https://www.amazon.co.uk/dp/B09Y7CT2XW',
    appleLink: 'https://books.apple.com/us/book/downfall/id1619568244?ls=1',
    koboLink: 'https://www.kobo.com/us/en/ebook/downfall-61',
    bnLink: 'https://www.barnesandnoble.com/w/downfall-sean-allen/1141360542?ean=2940160917672',
    bnPBLink: 'https://www.barnesandnoble.com/w/downfall-sean-allen/1141360542?ean=9781953949172',
    bnHCLink: 'https://www.barnesandnoble.com/w/downfall-sean-allen/1141360542?ean=9781953949189',
    image: 'DownfallWebCover_LARGE.jpeg',
    thumbImg: 'DownfallThumb.jpeg',
    imgPosition: 'left',
    textPosition: 'right',
    blurb: {
      __html: `<p><span class="highlight-yellow">Evil is marching across the galaxy… 
      and no one is guaranteed to survive.</span></p>
      
      <p><span class="highlight-yellow">Tyrobus Daelekon burns for one last chance to reclaim his honor.</span> 
      But when he agrees to help his homeworld’s high queen battle a formidable enemy, 
      he might be leading his crew to their doom.</p>

      <p><span class="highlight-yellow">Dezmara Strykar’s revelations come rife with hard decisions. </span>
      But whether she finally sets foot on Earth or fights alongside her 
      loved ones, she fears either course will lead to tragedy.</p>

      <p><span class="highlight-yellow">Otto Von Holt is on a suicide mission.</span> Determined to rescue his friends 
      from heavily armed cannibals, he’s certain his quest into dungeons bristling 
      with dark magic will claim his last breath.</p>

      <p><span class="highlight-yellow">As warring factions hurtle toward galactic Armageddon, three desperate 
      champions embark on the inevitable path to destiny.</span> But with a savage 
      adversary conquering the stars, only their deaths await…</p>

      <p><span class="highlight-yellow">Who will perish, and who will rise to victory?</span></p>

      <p><i>Downfall</i> is the intrepid fifth book in The D-Evolution space opera fantasy series. 
      <span class="highlight-yellow">If you like gutsy heroes, terrifying villains, and large-scale action,</span> 
      then you’ll love Sean Allen’s jaw-dropping conclusion.</p>
      
      <p>Buy <i>Downfall</i> to defend the cosmos from darkness today!</p>`
    },
    extract: {
      __html: `
        <h3 class="extract-header">A nice little placeholder</h3>
        
      `
    },
    reviews: [
      {
        __html: `<blockquote class="review__text">
        "Dezmara Strykar is a heroine with a quick wit and insane skills. 
        She’s River Tam on steroids...with an awesome flight suit, helmet, and weaponry."
        </blockquote>
        <span class="review__reviewer-tag">
        &mdash;Jake Hasson, <span class="highlight-yellow qualification">RantingDragon.com</span>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "Every scene involving this high octane smuggler was heart-pounding fun."
        </blockquote>
        <span class="review__reviewer-tag">
        &mdash;Jake Hasson, <span class="highlight-yellow qualification">RantingDragon.com</span>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "Fast, furious and a great deal of fun."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Antony Jones, <span class="highlight-yellow qualification">SFBook.com</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "A space opera adventure romp of the first order."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Ove Jansson, <span class="highlight-yellow qualification">Cybermage.se</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "Goes from zero to sixty in a matter of chapters...it blew me away!"
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;Jessica Torres, <span class="highlight-yellow qualification">Hopelessly Devoted Bibliophile</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "An action-packed spectacle...a great time."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;<span class="highlight-yellow qualification">The Eloquent Page</div>
        </span>`
      },
      {
        __html: `<blockquote class="review__text">
        "A fascinating and fast-paced science-fiction read, very much recommended."
        </blockquote>
        <div class="review__reviewer-tag">
        &mdash;<span class="highlight-yellow qualification">Midwest Book Review</div>
        </span>`
      },
    ]
  },
}