import React from 'react';
import { Helmet } from 'react-helmet';
import Head from '../components/head';
import Layout from '../components/layout';
import SiteIntro from '../components/siteIntro';
import { booksMeta } from '../lib/booksMeta';
import Hero from '../components/hero';
import BlogTweetSplit from '../components/blogTweetSplit';
import ImageSlider from '../components/imageSlider';
import NewsLetterSignUp from '../components/newsLetterSignUp';

const IndexPage = () => {
  const data = booksMeta.Deceit
  const PAGE = "Home";
  const baseSiteUrl = 'https://authorseanallen.com';

  return (
    <Layout showHeader={true}>
      <Head title={PAGE} />
      <Helmet>
        <meta name="description"
          content="Internet home for genre fiction author Sean Allen." />
        <link rel="canonical" href={baseSiteUrl} />
      </Helmet>
      <NewsLetterSignUp
        sourceName='group[80868][1]'
        sourceValue='1'
      />
      <SiteIntro doubleColumn={true} />
      <Hero
        title={data.title}
        image={data.image}
        imgPosition={data.imgPosition}
        textPosition={data.textPosition}
        blurb={data.blurb}
        releaseDate={data.releaseDate}
        nextBookName={data.nextBookName}
        nextInSeries={data.nextInSeries}
        nextBookImage={data.nextBookImage}
        amazonUSLink={data.amazonUSLink}
        amazonUKLink={data.amazonUKLink}
        appleLink={data.appleLink}
        koboLink={data.koboLink}
        bnHCLink={data.bnHCLink}
        container="deceit"
      />
      <ImageSlider />
      <BlogTweetSplit />
    </Layout>
  )
}

export default IndexPage;