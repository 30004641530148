import React from 'react';
import TwitterTimeline from './twitterTimeline';
import { Link, graphql, useStaticQuery } from 'gatsby';


const BlogTweetSplit = (props) => {

  const blogData = useStaticQuery(graphql`
    query {
      allContentfulBlogPost (
        sort: {
          fields: publishedDate,
          order:DESC
        }
      ) {
        edges {
          node {
            title
            slug
            tags
            publishedDate( formatString:"MMMM Do, YYYY")
          }
        }
      }
    }
  `);

  const allPosts = blogData.allContentfulBlogPost.edges;

  const renderContentfulBlog = (posts) => {
    return posts.map((post, idx) => {
      return (
        <React.Fragment key={idx}>
          <li className="post">
            <h3 className="post-title">
              <Link className="blog-link" to={`/blog/${post.node.slug}`}>
                {post.node.title}
              </Link>
            </h3>
            <p className="post-pub-date">{post.node.publishedDate}</p>
          </li>
        </React.Fragment>
      )
    })
  }

  return (
    <section className="hero dark BlogTweetSplit">
      <div className="content">
        <div className="blog-tweets-split-box">
          <div className="blog-split">
            <h2 className="latest-news-title">Latest Updates</h2>
            <ul className="latest-news-container">
              {renderContentfulBlog(allPosts)}
            </ul>
          </div>
          <div className="tweets-container">
            {/* <h2 className="recent-tweets-title">Social Posts</h2> */}
            <div className="tweets-split">
              {/* <TwitterTimeline /> */}
              {/* <a
                className="twitter-timeline"
                href="https://twitter.com/AuthorSeanAllen?ref_src=twsrc%5Etfw">Tweets by SeanAllenAuthor
            </a>
              <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script> */}
            </div>
          </div>
        </div>
      </div>
      <hr />
    </section>
  )
}

export default BlogTweetSplit;