import React from 'react';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageSlider = () => {
  const settings = {
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    // centerMode: true, //* hoses responsive break points.
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 351,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  }

  const deceitImgUrl = require(`../images/sliderCovers/DeceitCoverSliderImage.jpeg`);
  const declineImgUrl = require(`../images/sliderCovers/DeclineCoverSliderImage.jpeg`);
  const dishonorImgUrl = require(`../images/sliderCovers/DishonorCoverSliderImage.jpeg`);
  const deicideImgUrl = require(`../images/sliderCovers/DeicideCoverSliderImage.jpeg`);
  const downfallImgUrl = require(`../images/sliderCovers/DownfallCoverSliderImage.jpeg`);

  return (
    <section className="hero dark">
      <h1 className="hero-title">Books</h1>
      <div className="content">
        <div className="ImageSlider">
          <Slider {...settings}>
            <div className="carousel-img-container">
              <Link to='/Deceit'>
                <img src={deceitImgUrl} alt="Deciet book cover" />
              </Link>
            </div>
            <div className="carousel-img-container">
              <Link to='Decline'>
                <img src={declineImgUrl} alt="Decline book cover" />
              </Link>
            </div>
            <div className="carousel-img-container">
              <Link to='Dishonor'>
                <img src={dishonorImgUrl} alt="Dishonor book cover" />
              </Link>
            </div>
            <div className="carousel-img-container">
              <Link to='Deicide'>
                <img src={deicideImgUrl} alt="Deicide book cover" />
              </Link>
            </div>
            <div className="carousel-img-container">
              <Link to='Downfall'>
                <img src={downfallImgUrl} alt="Downfall book cover" />
              </Link>
            </div>
          </Slider>
        </div>
      </div>
      <hr />
    </section>
  );
}

export default ImageSlider;
