import React, { useState, useEffect } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const NewsLetterSignUp = ({ sourceName, sourceValue }) => {
  const [listFields, setListFields] = useState({ [sourceName]: sourceValue });
  const [bookCoverLoaded, setBookCoverLoaded] = useState(false);
  const [posterLoaded, setPosterLoaded] = useState(false);
  const [form, setValues] = useState({
    email: '',
  });

  useEffect(() => {
    // loadCoverImage();
    loadPosterImage();
  });

  const loadCoverImage = () => {
    if (bookCoverLoaded) return;

    const imageUrl = require(`../images/coverThumbNails/DeceitThumb.jpg`);
    let imgContainer = document.querySelector(".book-cover-container");

    if (!imgContainer) return;

    imgContainer.classList.add("loading");
    let preloadedCover = document.createElement("img");
    preloadedCover.src = imageUrl;
    preloadedCover.alt = `Deceit Cover`
    preloadedCover.style.width = '250px';

    preloadedCover.addEventListener('load', (event) => {
      imgContainer.classList.remove("loading");
      imgContainer.appendChild(preloadedCover);
      preloadedCover = null;
    });

    setBookCoverLoaded(true);
  }

  const loadPosterImage = () => {
    if (posterLoaded) return;

    const imageUrl = require(`../images/DezmaraPosterThumb.png`);
    let imgContainer = document.querySelector(".poster-container");

    if (!imgContainer) return;

    imgContainer.classList.add("loading");
    let preloadedPoster = document.createElement("img");
    preloadedPoster.src = imageUrl;
    preloadedPoster.alt = `Dezmara Poster`;
    preloadedPoster.style.width = '240px';

    preloadedPoster.addEventListener('load', (event) => {
      imgContainer.classList.remove("loading");
      imgContainer.appendChild(preloadedPoster);
      preloadedPoster = null;
    });

    setPosterLoaded(true);
  }


  const handleSubmit = async () => {
    const resultsPayload = await addToMailchimp(form.email, listFields);
    // console.log('Results:', resultsPayload);
    setValues({ ...form, email: '' });
    if (resultsPayload.result === 'success') {
      fireSuccessNotice();
    } else {
      fireFailureNotice(resultsPayload);
    }
  }

  const fireSuccessNotice = () => {
    const notice = withReactContent(Swal);
    notice.fire({
      icon: 'success',
      title: 'Aw, yeah...',
      background: '#28135D',
      confirmButtonColor: '#F3BC0B',
      width: '40rem',
      scrollbarPadding: true,
      text: `Thanks for joining my newsletter! Keep an eye on your inbox 
      for links to download your free book and poster. Feel free to stick 
      around and explore more of the D-Evolution Universe. Cheers!`,
    })
  }

  const cancelJoinConfirmation = () => {
    const notice = withReactContent(Swal);
    notice.fire({
      icon: 'error',
      title: 'Mission Aborted.',
      background: '#28135D',
      confirmButtonColor: '#F3BC0B',
      width: '40rem',
      scrollbarPadding: true,
      html: `Sorry things didn't work out. <span class="highlight-white">
      This site does NOT store your email</span>, and you won't
      get any notifications from Sean Allen. Cheers!`,
    })
  }


  const disclaimerNotice = () => {
    const notice = withReactContent(Swal);
    notice.fire({
      icon: 'info',
      title: 'Heads up...',
      background: '#28135D',
      confirmButtonColor: '#F3BC0B',
      width: '40rem',
      scrollbarPadding: true,
      showCancelButton: true,
      confirmButtonText: 'JOIN',
      cancelButtonText: 'ABORT',
      reverseButtons: true,
      html: `<span class="highlight-white">The D-Evolution blends space, magic, swords,
      guns, and technology</span>. There's also foul language and violence. 
      If you're still keen to press on, welcome aboard. If not, thanks for visiting!`
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit(form.email, { listFields });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        cancelJoinConfirmation();
      }
    })
  }

  const fireFailureNotice = (results) => {
    const notice = withReactContent(Swal);
    notice.fire({
      icon: 'error',
      title: 'Oops...',
      background: '#43209d',
      confirmButtonColor: '#F3BC0B',
      width: '40rem',
      scrollbarPadding: true,
      html: `<span class="alert-wrapper">${results.msg}</span>`,
    })
  }

  const fireGenericFailureNotice = () => {
    const notice = withReactContent(Swal);
    notice.fire({
      icon: 'error',
      title: 'Oops...',
      background: '#43209d',
      confirmButtonColor: '#F3BC0B',
      width: '40rem',
      scrollbarPadding: true,
      text: `Something went wrong. Please try again.`,
    })
  }

  const inactiveFormNotice = () => {
    const notice = withReactContent(Swal);
    notice.fire({
      icon: 'error',
      title: 'Oops...',
      background: '#28135D',
      confirmButtonColor: '#F3BC0B',
      width: '40rem',
      scrollbarPadding: true,
      text: `This feature isn't live yet. Stay tuned!`,
    })
  }

  const updateField = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  }

  return (
    <section className="signup-form-wrapper">
      <section className="offer-and-input">
        <div className="newsletter-incentive-blurb">
          <h2 className="offer-title">
            Join the Dissension
          </h2>
          <span className="highlight-yellow">Do you want original artwork </span>
          from the D-Evolution universe <span className="highlight-yellow">absolutely FREE? </span>
          The art is a collaboration between infamous digital maestro <a href="https://www.mattdixon.co.uk" target="_blank" rel="noopener noreferrer">Matt Dixon</a> and graphic
          designer to the stars <a href="http://www.3deucesdesign.com/" target="_blank" rel="noopener noreferrer">Don Schaeffer</a>. All you have to do is sign up for my newsletter.
          It'll be chock-full of interesting tidbits, witty remarks, and updates that'll hit your
          inbox before I post them anywhere else. No spam&mdash;I promise&mdash;and you can
          unsubscribe any time.
        </div>
        <div className="input-controls full-size">
          <input
            disabled={false}
            name="email"
            type="email"
            placeholder="Email"
            onChange={updateField}
            value={form.email}
            required
          />
          <button
            disabled={false}
            className="btn yellow inline-block"
            onClick={() => disclaimerNotice()}>Join Up
          </button>
        </div>
      </section>
      <div className="newsletter-incentive-graphics">
        {/* <div className="book-cover-container loading"> */}
        {/* //* image dynamically inserted */}
        {/* </div> */}
        {/* <div className="plus-sign">+</div> */}
        <div className="poster-container loading">
          {/* //* image dynamically inserted */}
        </div>
      </div>
      <div className="input-controls mobile">
        <p>Get the poster <span className="highlight-white">FREE.</span> Just sign up with
          a valid email address.
        </p>
        <div className="field-and-submit">
          <input
            disabled={false}
            name="email"
            type="email"
            placeholder="Email"
            onChange={updateField}
            value={form.email}
            required
          />
          <button
            disabled={false}
            className="btn yellow inline-block"
            onClick={() => disclaimerNotice()}>Join Up
          </button>
        </div>
      </div>
    </section>
  )
}

export default NewsLetterSignUp;
